import { createSlice } from "@reduxjs/toolkit";

import {
  addExchangerCPConnectionReducer,
  changeCalculatorSettingsReducer,
  changeExchangerServiceStatusReducer,
  changeExchangerSettingsReducer,
  changeMonthlyLimitReducer,
  changeWidgetStatusExchangerReducer,
  deleteCalculatorSettingsReducer,
  fetchCalculatorSettingsReducer,
  fetchExchangerDetailsReducer,
  fetchExchangerServicesReducer, fetchExchangerSettingsReducer,
  fillExchangerConnectionsReducer,
  getExchangerCPProjectIdReducer,
  getExchangerSecretCPConnectionReducer,
  putStatusExchangerReducer,
} from "./exchangerDetailsReducers";

const initialState = {
  exchanger: null,
  error: null,
  loading: true,
  services: {
    loading: true,
    list: [],
    paginationInfo: {
      totalItems: null,
      totalPageCount: null,
      itemsPerPage: null,
      page: null,
    },
  },
  cpConnection: {
    loading: false,
    projectId: "",
    secret: "",
  },
  changeExchangerStatusStateLoading: false,
  exchangerCPConnectionsLoading: false,
  exchangerFillConnectionsLoading: false,
  changeMonthlyLimitLoading: false,
  settings: {
    data: null,
    loading: true,
    changeStatusLoading: false,
  },
  calculatorSettings: {
    data: [],
    loading: true,
    changeCalculatorSettingsLoading: false,
  },
};

const exchangerDetailsSlice = createSlice({
  name: "exchangerDetailsSlice",
  initialState,
  reducers: {
    clearExchangerDetailsStore: (state) => {
      state.exchanger = [];
      state.cpConnection = {};
      state.loading = true;
    },
    clearExchangerServicesStore: (state) => {
      state.services.list = [];
      state.services.loading = true;
    },
    clearExchangerSettingsStore: (state) => {
      state.settings.data = [];
      state.settings.loading = true;
    },
    clearCalculatorSettingsStore: (state) => {
      state.calculatorSettings.data = [];
      state.settings.loading = true;
    },
  },
  extraReducers: (builder) => {
    fetchExchangerDetailsReducer(builder);
    fetchExchangerServicesReducer(builder);
    changeExchangerSettingsReducer(builder);
    addExchangerCPConnectionReducer(builder);
    fillExchangerConnectionsReducer(builder);
    changeExchangerServiceStatusReducer(builder);
    getExchangerSecretCPConnectionReducer(builder);
    getExchangerCPProjectIdReducer(builder);
    changeWidgetStatusExchangerReducer(builder);
    putStatusExchangerReducer(builder);
    fetchCalculatorSettingsReducer(builder);
    changeCalculatorSettingsReducer(builder);
    changeMonthlyLimitReducer(builder);
    deleteCalculatorSettingsReducer(builder);
    fetchExchangerSettingsReducer(builder);
  },
});

export const { clearExchangerDetailsStore, clearExchangerServicesStore, clearExchangerSettingsStore, clearCalculatorSettingsStore } =
  exchangerDetailsSlice.actions;

export default exchangerDetailsSlice.reducer;
