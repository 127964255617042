import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import { changePairMulti } from "../pair/asyncPairsSlice";
import {
  changeReferralWidgetCustomFee,
  changeReferralWidgetStatus,
  changeWidgetPair,
  changeWidgetPairMulti, changeWidgetPairMultiForAll,
  changeWidgetSettings,
  createReferralWidget,
  fetchReferralWidgetById,
  fetchReferralWidgets,
  fetchWidgetPairs,
  fetchWidgetReports
} from "./asyncReferralWidget";

const fetchReferralWidgetsReducer = (builder) => {
  builder.addCase(fetchReferralWidgets.fulfilled, (state, action) => {
    state.widgets = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchReferralWidgets.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchReferralWidgetByIdReducer = (builder) => {
  builder.addCase(fetchReferralWidgetById.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchReferralWidgetById.fulfilled, (state, action) => {
    state.widget = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchReferralWidgetById.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const createReferralWidgetReducer = (builder) => {
  builder.addCase(createReferralWidget.pending, (state) => {
    state.createWidgetLoading = true;
  });
  builder.addCase(createReferralWidget.fulfilled, (state, action) => {
    const newWidget = action.payload.data;
    state.widgets = [...state.widgets, newWidget];

    action.payload.callback();
    state.createWidgetLoading = false;
    closableNotification(t("alerts.widgetCreated"), "success");
  });
  builder.addCase(createReferralWidget.rejected, (state, action) => {
    errorsParser(action.payload);
    state.createWidgetLoading = false;
  });
};

const changeReferralWidgetStatusReducer = (builder) => {
  builder.addCase(changeReferralWidgetStatus.pending, (state) => {
    state.changeWidgetStatusLoading = true;
  });
  builder.addCase(changeReferralWidgetStatus.fulfilled, (state, action) => {
    state.widget = action.payload.data;
    state.changeWidgetStatusLoading = false;
    closableNotification(t("alerts.widgetStatusSuccessfullyChanged"), "success");
  });
  builder.addCase(changeReferralWidgetStatus.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeWidgetStatusLoading = false;
  });
};

const fetchWidgetPairsReducer = (builder) => {
  builder.addCase(fetchWidgetPairs.pending, (state) => {
    state.pairs.loading = true;
  });
  builder.addCase(fetchWidgetPairs.fulfilled, (state, action) => {
    state.pairs.data = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.pairs.loading = false;
  });
  builder.addCase(fetchWidgetPairs.rejected, (state, action) => {
    errorsParser(action.payload);
    state.pairs.loading = false;
  });
};

const changeWidgetSettingsReducer = (builder) => {
  builder.addCase(changeWidgetSettings.pending, (state) => {
    state.changeWidgetSettingsLoading = true;
  });
  builder.addCase(changeWidgetSettings.fulfilled, (state, action) => {
    state.widget = { ...state.widget, ...action.payload?.data?.widgetSettings };
    closableNotification(t("alerts.widgetSettingsUpdated"), "success");
    state.changeWidgetSettingsLoading = false;
  });
  builder.addCase(changeWidgetSettings.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeWidgetSettingsLoading = false;
  });
};

const changePairPercentReducer = (builder) => {
  builder.addCase(changeWidgetPair.pending, (state) => {
    state.pairs.changeParamsLoading = true;
  });
  builder.addCase(changeWidgetPair.fulfilled, (state, action) => {
    const newObjectPair = action.payload.data;

    state.pairs.data = state.pairs.data.map((item) => {
      if (item.id === newObjectPair.id) {
        return newObjectPair;
      }
      return item;
    });

    action.payload.notificationsCallback && action.payload.notificationsCallback();
    state.errors = null;
    state.pairs.changeParamsLoading = false;
  });
  builder.addCase(changeWidgetPair.rejected, (state, action) => {
    errorsParser(action.payload);
    state.pairs.changeParamsLoading = false;
  });
};

const fetchWidgetReportsReducer = (builder) => {
  builder.addCase(fetchWidgetReports.pending, (state) => {
    state.reports.loading = true;
  });
  builder.addCase(fetchWidgetReports.fulfilled, (state, action) => {
    state.reports.data = action.payload.data;
    state.reports.loading = false;
  });
  builder.addCase(fetchWidgetReports.rejected, (state, action) => {
    errorsParser(action.payload);
    state.reports.loading = false;
  });
};

const changeReferralWidgetCustomFeeReducer = (builder) => {
  builder.addCase(changeReferralWidgetCustomFee.pending, (state) => {
    state.changeCustomFeeStatusLoading = true;
  });
  builder.addCase(changeReferralWidgetCustomFee.fulfilled, (state, action) => {
    state.widget = action.payload.data;
    state.changeCustomFeeStatusLoading = false;
    closableNotification(t("alerts.widgetFeeStatusSuccessfullyChanged"), "success");
  });
  builder.addCase(changeReferralWidgetCustomFee.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeCustomFeeStatusLoading = false;
  });
};

const changeWidgetPairMultiReducer = (builder) => {
  builder.addCase(changeWidgetPairMulti.pending, (state) => {
    state.changeParamsLoading = true;
    state.changeWidgetStatusLoading = true;
  });
  builder.addCase(changeWidgetPairMulti.fulfilled, (state, action) => {
    const newArrayPair = action.payload.data;

    state.pairs.data = state.pairs.data.map((item) => {
      const updatedPair = newArrayPair.find((newItem) => newItem.id === item.id);

      if (updatedPair) {
        return {
          ...item,
          isActive: updatedPair.isActive,
          percent: updatedPair.percent,
        };
      }

      return item;
    });

    closableNotification(t("alerts.allPairUpdated"), "success");
    state.errors = null;

    state.changeParamsLoading = false;
    state.changeWidgetStatusLoading = false;
  });
  builder.addCase(changeWidgetPairMulti.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeParamsLoading = false;
    state.changeWidgetStatusLoading = false;
  });
};

const changeWidgetPairMultiForAllReducer = (builder) => {
  builder.addCase(changeWidgetPairMultiForAll.pending, (state) => {
    state.refreshing = true;
  });
  builder.addCase(changeWidgetPairMultiForAll.fulfilled, (state, action) => {
    const { data } = action.payload;

    closableNotification(data, "success");
    state.errors = null;
    state.refreshing = true;
  });
  builder.addCase(changeWidgetPairMultiForAll.rejected, (state, action) => {
    errorsParser(action.payload);
    state.refreshing = false;
  });
};

export {
  changePairPercentReducer,
  changeReferralWidgetCustomFeeReducer,
  changeReferralWidgetStatusReducer,
  changeWidgetPairMultiReducer,
  changeWidgetSettingsReducer,
  createReferralWidgetReducer,
  fetchReferralWidgetByIdReducer,
  fetchReferralWidgetsReducer,
  fetchWidgetPairsReducer,
  fetchWidgetReportsReducer,
  changeWidgetPairMultiForAllReducer,
};
