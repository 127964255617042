import styled from "styled-components";

import { StyledButton } from "../../styles/styledButton";

const inputStyleTemplate = (theme) => {
  return `
    width: 100%;
    padding: 12px 16px;
    color: ${theme.input.textColor};
    font-family: "Inter", sans-serif;
    font-size: 14px;
    background-color: ${theme.input.backgroundColor};
    border: 1px solid ${theme.input.borderColor};
    border-radius: 12px;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    transition: all .3s ease;
  
    &:hover, &:focus, &:active {
      border-color: ${theme.primary.first};
    }
    
    &::placeholder {
      color: ${theme.input.placeholderColor};
    }
    
    &:-webkit-autofill {
      -webkit-text-fill-color: ${theme.input.textColor};
      -webkit-box-shadow: inset 0 0 0 50px ${theme.input.backgroundColor};
    }
    
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    &:read-only {
      color: ${theme.input.readonlyColor};
    }
    
    &:read-only:focus{
      box-shadow: none;
      background-color: transparent;
    }
    
    @media screen and (max-width: 576px) {
      padding: 11px 16px;
      font-size: 16px;
    }
  `;
};

export const StyledInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  ${({ type }) =>
    type === "hidden" &&
    `
    height: 0;
    margin: 0 !important;
    visibility: hidden;
    opacity: 0;
  `};
`;

export const StyledLabel = styled.label`
  padding-bottom: 12px;
  color: ${({ theme }) => theme.neutral.second};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  display: flex;
`;

export const StyledTextarea = styled.textarea`
  height: 125px;
  font-family: inherit;
  resize: none;
  border: none;
  outline: none;
  ${({ theme }) => inputStyleTemplate(theme)}
`;

export const StyledInput = styled.input`
  ${({ theme }) => inputStyleTemplate(theme)}
  ${({ icon }) => icon && "padding-right: 40px"};
  ${({ type }) => type === "password" && "padding-right: 40px"};
  position: relative;

  @media screen and (max-width: 768px) {
    ${({ icon }) => icon && "padding-right: 36px"};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.neutral.eight};
  }
`;

export const StyledDelayInput = styled.div`
  input {
    font-family: inherit;
    ${({ theme }) => inputStyleTemplate(theme)}
  }
`;

export const StyledMaskInput = styled.div`
  input {
    ${({ theme }) => inputStyleTemplate(theme)}
    ${({ icon }) => icon && "padding-right: 40px"};
    position: relative;

    @media screen and (max-width: 768px) {
      ${({ icon }) => icon && "padding-right: 36px"};
    }
  }
`;

export const StyledInputWrapper = styled.div`
  position: relative;

  ${({ error, theme }) =>
    error &&
    `input, textarea {
      word-break: break-word;
      border: 1px solid ${theme.danger.first};
      
      &:focus, &:active {
        border: 1px solid ${theme.danger.first};
      }
    }`};

  ${StyledButton} {
    min-width: 26px;
    min-height: 26px;
    padding: 3px 6px;
    font-size: 12px;
    border-width: 1px;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    right: 6px;
    transform: translateY(-50%);

    &.edit-btn {
      position: static;
      border-radius: 0;
      border: none;

      &:hover {
        background-color: transparent;
      }
    }

    span {
      font-size: 14px;
    }

    &:disabled,
    &:active {
      transform: scale(0.98) translateY(-50%) translateZ(0);
    }
  }


  &:has(.edit-buttons-wrapper) > input {
    padding-right: 60px;
  }

  .edit-buttons-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 8px;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);

    ${StyledButton} {
      min-width: 14px;
      min-height: 14px;
      width: 14px;
      height: 14px;
      position: static;
      transform: none;

      .icon-ex-ok {
        color: ${({ theme }) => theme.success.first};
        font-size: 11px;
      }

      .icon-ex-cancel {
        color: ${({ theme }) => theme.danger.first};
        font-size: 11px;
      }
    }
  }
`;

export const StyledDecorationInputIcon = styled.span`
  color: ${({ theme }) => theme.input.inputIconColor};
  font-size: 14px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  pointer-events: none;
  

  &[class*="icon-ex-input-eye-slash"],
  &[class*="icon-ex-input-eye"] {
    cursor: pointer;
    pointer-events: auto;

    &:hover {
      color: ${({ theme }) => theme.input.readonlyColor};
    }
  }

  @media screen and (max-width: 768px) {
    height: fit-content;
    font-size: 16px;
  }
`;

export const StyledError = styled.div`
  padding-top: 3px;
  color: ${({ theme }) => theme.danger.first};
  font-size: 12px;
  text-align: left;
`;

export const StyledFixedError = styled.div`
  height: 20px;
  color: ${({ theme }) => theme.danger.first};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  font-size: 12px;
  text-align: left;
  word-break: break-word;
`;

export const StyledDivider = styled.div`
  width: 100%;
  ${({ height }) => (height ? `height: ${height}px` : "height: 1px")};
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  background-color: ${({ theme }) => theme.neutral.five};
`;
