import { createSlice } from "@reduxjs/toolkit";

import { defaultItemsPerPage } from "../../../utils/consts";
import { geItemsPerPage } from "../../../utils/customFunc/getItemsPerPage";
import {
  createPaymentReducer,
  createPayoutReducer,
  fetchAvailableCurrenciesReducer,
  fetchAvailablePaymentSystemsReducer,
  fetchAvailableServicesReducer,
  fetchExchangerPairUnitDataReducer,
  fetchPaymentTransactionAttributesReducer,
  fetchPayoutTransactionAttributesReducer,
  fetchTransactionsListReducer,
} from "./transactionsReducers";
import { PusherService } from "../../../utils/socketPusherService";

const initialState = {
  errors: null,
  loading: true,
  isPaymentCreated: false,
  isPayoutCreated: false,
  isTransactionCreated: false,
  allTransactionsList: [],
  paymentList: [],
  payoutList: [],
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
  createLoading: false,
  selectLoading: false,
  availableServicesList: [],
  availableCurrenciesList: [],
  availablePaymentSystemList: [],
  pairUnitId: null,
  paymentAttributes: null,
  payoutAttributes: null,
};

const transactionsSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {
    clearTransactionsStore: (state) => {
      state.errors = null;
      state.loading = true;
      state.isPaymentCreated = false;
      state.isPayoutCreated = false;
      state.allTransactionsList = [];
      state.paymentList = [];
      state.payoutList = [];
      state.paginationInfo = {
        totalItems: null,
        totalPageCount: null,
        itemsPerPage: null,
      };
      state.createLoading = false;
      state.selectLoading = false;
      state.availableServicesList = [];
      state.availableCurrenciesList = [];
      state.availablePaymentSystemList = [];
      state.pairUnitId = null;
      state.paymentAttributes = null;
      state.payoutAttributes = null;
    },
    resetIsTransactionCreated: (state) => {
      state.isTransactionCreated = false;
    },
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    setCreateTransactionsErrors: (state, action) => {
      state.errors = action.payload;
    },
    resetTransactionAttribute: (state) => {
      state.paymentAttributes = null;
      state.payoutAttributes = null;
    },
    resetPairUnitId: (state) => {
      state.pairUnitId = null;
    },
    updateTransactionStore: (state, action) => {
      let arrayToUpdate;

      switch (action.payload.type) {
        case "payment": {
          arrayToUpdate = state.paymentList || [];
          break;
        }
        case "payout": {
          arrayToUpdate = state.payoutList || [];
          break;
        }
        default: {
          arrayToUpdate = state.allTransactionsList || [];
          break;
        }
      }

      PusherService.updateOrPushObjectInList(arrayToUpdate, action.payload.data);
    }
  },
  extraReducers: (builder) => {
    fetchAvailablePaymentSystemsReducer(builder);
    fetchPaymentTransactionAttributesReducer(builder);
    fetchPayoutTransactionAttributesReducer(builder);
    fetchExchangerPairUnitDataReducer(builder);
    fetchAvailableCurrenciesReducer(builder);
    fetchAvailableServicesReducer(builder);
    fetchTransactionsListReducer(builder);
    createPaymentReducer(builder);
    createPayoutReducer(builder);
  },
});

export const {
  clearTransactionsStore,
  resetIsTransactionCreated,
  setPaginationInfo,
  setCreateTransactionsErrors,
  resetTransactionAttribute,
  resetPairUnitId,
  updateTransactionStore
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
