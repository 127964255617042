export const permissionMainMerchantEndpoints = {
  CREATE_EXCHANGER: {
    name: "create_exchanger",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PUT_CHANGE_AVATAR: {
    name: "put_changer_avatar",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PUT_REMOVE_AVATAR: {
    name: "put_remove_avatar",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_BALANCES_GET_COLLECTION: {
    name: "api_balances_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_BALANCES_GET_ITEM: {
    name: "api_balances_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_POST_COLLECTION: {
    name: "api_exchangers_post_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_GET_COLLECTION: {
    name: "api_exchangers_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_GET_ITEM: {
    name: "api_exchangers_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_GET_OWN_EXCHANGER_BY_USER_COLLECTION: {
    name: "api_exchangers_get_own_exchanger_by_user_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_CONNECTION_GET_COLLECTION: {
    name: "api_exchanger_connections_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_CONNECTION_GET_ITEM: {
    name: "api_exchanger_connections_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  HANDLE_EXCHANGER_SETTINGS: {
    name: "handle_exchanger_settings",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_SETTINGS_GET_COLLECTION: {
    name: "api_exchanger_settings_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_SETTINGS_GET_ITEM: {
    name: "api_exchanger_settings_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_SETTINGS_PATCH_ITEM: {
    name: "api_exchanger_settings_patch_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_USERS_GET_ITEM: {
    name: "api_users_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_GET_EXCHANGER_RATES_COLLECTION: {
    name: "get_exchanger_rates_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  CHANGE_EXCHANGER_RATES_MARKUP: {
    name: "change_exchanger_rates_markup",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_RATES_CHANGE_EXCHANGER_MARKUP_ITEM: {
    name: "api_exchanger_rates_change_exchanger_markup_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_USERS_CHANGE_PASSWORD: {
    name: "put_users_change_password",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  TRANSACTION_ATTRIBUTES_BY_PAIR_UNIT: {
    name: "transaction_attributes_by_pair_unit",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  CREATE_PAYMENT: {
    name: "create_payment",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  CREATE_PAYOUT: {
    name: "create_payout",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_PAYMENT_SYSTEMS_GET_COLLECTION: {
    name: "api_payment_systems_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_PAYMENT_SYSTEMS_GET_ITEM: {
    name: "api_payment_systems_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_AVAILABLE_PAYMENT_SYSTEMS: {
    name: "get_available_payment_systems",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_SERVICES_GET_COLLECTION: {
    name: "api_services_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_SERVICES_GET_ITEM: {
    name: "api_services_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_AVAILABLE_SERVICES: {
    name: "get_available_services",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_USER_PAIR_UNITS: {
    name: "get_user_pair_units",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_RATE_SERVICES: {
    name: "get_rate_services",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_PAIR_UNITS_GET_COLLECTION: {
    name: "api_pair_units_get_collection",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  API_PAIR_UNITS_GET_ITEM: {
    name: "api_pair_units_get_item",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_EXCHANGER_PAIR_UNIT: {
    name: "get_exchanger_pair_unit",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_INVOICES_LIST: {
    name: "get_invoices_list",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_INVOICE_DETAILS: {
    name: "get_invoice_detail",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  GET_AUTHENTICATOR_SECRET_IS_ACTIVE: {
    name: "get_authenticator_secret_is_active",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  REFRESH_TOKEN: {
    name: "refresh_token",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PUT_AUTHENTICATOR_SECRET_REMOVE: {
    name: "put_authenticator_secret_remove",
    hasFullAccess: false,
    isTwoFaRequired: true,
  },
  PUT_USER_PAIR_UNITS: {
    name: "put_user_pair_units",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  CHANGE_PAIR_UNITS_RATE_SERVICE: {
    name: "change_pair_units_rate_service",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  FILL_USER_PAIR_UNITS: {
    name: "fill_user_pair_units",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  PATCH_USER_PAIR_UNITS: {
    name: "patch_user_pair_units",
    hasFullAccess: false,
    isTwoFaRequired: false,
  },
  POST_CALCULATOR_SETTINGS: {
    name: "post_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  DELETE_CALCULATOR_SETTINGS: {
    name: "delete_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_CALCULATOR_SETTINGS: {
    name: "get_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  UPDATE_USER_PAIR_UNITS: {
    name: "update_user_pair_units",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
};
