import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  createTrafficLink,
  deleteTrafficLinkById,
  fetchTrafficLinkById,
  fetchTrafficLinks,
  fetchTrafficUsers,
  getReportsInfo,
} from "./asyncReports";

const getReportsInfoReducer = (builder) => {
  builder.addCase(getReportsInfo.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(getReportsInfo.fulfilled, (state, action) => {
    state.reportsInfo = action.payload.data.reportsInfo;
    state.loading = false;
  });
  builder.addCase(getReportsInfo.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchTrafficLinksReducer = (builder) => {
  builder.addCase(fetchTrafficLinks.pending, (state) => {
    state.traffic.loading = true;
  });
  builder.addCase(fetchTrafficLinks.fulfilled, (state, action) => {
    state.traffic.data = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.traffic.loading = false;
  });
  builder.addCase(fetchTrafficLinks.rejected, (state, action) => {
    errorsParser(action.payload);
    state.traffic.loading = false;
  });
};

const createTrafficLinkReducer = (builder) => {
  builder.addCase(createTrafficLink.pending, (state) => {
    state.traffic.createLoading = true;
  });
  builder.addCase(createTrafficLink.fulfilled, (state, action) => {
    state.traffic.data = [...state.traffic.data, action.payload.data];

    !!action.payload.callback && action.payload.callback();
    state.traffic.createLoading = false;

    closableNotification(t("alerts.trafficLinkCreated"), "success");
  });
  builder.addCase(createTrafficLink.rejected, (state, action) => {
    errorsParser(action.payload);
    state.traffic.createLoading = false;
  });
};

const fetchTrafficLinkByIdReducer = (builder) => {
  builder.addCase(fetchTrafficLinkById.pending, (state) => {
    state.trafficById.loading = true;
  });
  builder.addCase(fetchTrafficLinkById.fulfilled, (state, action) => {
    state.trafficById.data = action.payload.data;

    state.trafficById.loading = false;
  });
  builder.addCase(fetchTrafficLinkById.rejected, (state, action) => {
    errorsParser(action.payload);
    state.trafficById.loading = false;
  });
};

const deleteTrafficLinkByIdReducer = (builder) => {
  // builder.addCase(deleteTrafficLinkById.pending, (state) => {
  // });
  builder.addCase(deleteTrafficLinkById.fulfilled, (state, action) => {
    state.traffic.data = state.traffic.data.filter(({ id }) => id !== action.payload.id);
    closableNotification(t("alerts.trafficLinkDeleted"), "success");
  });
  builder.addCase(deleteTrafficLinkById.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

const fetchTrafficUsersReducer = (builder) => {
  builder.addCase(fetchTrafficUsers.pending, (state) => {
    state.trafficUsers.loading = true;
  });
  builder.addCase(fetchTrafficUsers.fulfilled, (state, action) => {
    state.trafficUsers.data = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.trafficUsers.loading = false;
  });
  builder.addCase(fetchTrafficUsers.rejected, (state, action) => {
    errorsParser(action.payload);
    state.trafficUsers.loading = false;
  });
};

export {
  createTrafficLinkReducer,
  deleteTrafficLinkByIdReducer,
  fetchTrafficLinkByIdReducer,
  fetchTrafficLinksReducer,
  fetchTrafficUsersReducer,
  getReportsInfoReducer,
};
