import { createSlice } from "@reduxjs/toolkit";

import {
  fetchExchangerCurrencyReducer,
  fetchExchangerPaymentSystemReducer,
  fetchExchangerServiceReducer,
  fetchMainCurrencyReducer,
} from "./filterHelpersReducers";

const initialState = {
  refetch: false,
  isFetching: false,
  paymentSystem: {
    list: [],
    loading: false,
    error: null,
  },
  currency: {
    list: [],
    loading: false,
    error: null,
  },
  service: {
    list: [],
    loading: false,
    error: null,
  },
};

const filterHelpersSlice = createSlice({
  name: "filterHelpers",
  initialState,
  reducers: {
    clearFilterHelpersStore: (state) => {
      state.paymentSystem = {};
      state.currency = {};
      state.service = {};
    },
    handleRefetch: (state) => {
      if (!state.isFetching) {
        state.refetch = !state.refetch;
        state.isFetching = true;
      }
    }
  },
  extraReducers: (builder) => {
    fetchExchangerPaymentSystemReducer(builder);
    fetchExchangerCurrencyReducer(builder);
    fetchExchangerServiceReducer(builder);
    fetchMainCurrencyReducer(builder);
    builder.addMatcher((action) => action.type.endsWith("/fulfilled") || action.type.endsWith("/rejected"), (state) => {
        state.isFetching = false;
      }
    );
  },
});

export const { clearFilterHelpersStore, handleRefetch } = filterHelpersSlice.actions;

export default filterHelpersSlice.reducer;
