import React from "react";

import { StyledButton } from "../../styles/styledButton";
import Spinner from "./Spinner";
import { StyledLoadButton } from "./styledSpinner";

const LoadButton = ({ text, className, spinnerColor, width, fullWidth, ...buttonProps }) => {
  return (
    <StyledLoadButton width={width} fullWidth={fullWidth}>
      <StyledButton as="div" className={`loading-button ${className}`} {...buttonProps} disabled>
        {text}
        <div className="loading-button__spinner">
          <Spinner color={spinnerColor || "#000000"} size="22px" />
        </div>
      </StyledButton>
    </StyledLoadButton>
  );
};

export default LoadButton;
