import jwt_decode from "jwt-decode";
import Dialog from "rc-dialog";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { StyledButton } from "../../styles/styledButton";

const OldUserModal = () => {
  const { t } = useTranslation("translation", { keyPrefix: "alerts.oldUserLoginModal" });

  const [visible, setVisible] = useState(false);

  const authState = useSelector((store) => store.auth);

  const { authenticated } = authState;

  const isOldUserCheck = () => {
    if (authenticated) {
      const token = localStorage.getItem("token");
      const isOldUserModal = sessionStorage.getItem("firstModalAgree");

      const { isOldUser } = jwt_decode(token);

      if (isOldUser && !isOldUserModal) {
        setVisible(true);
      }
    }
  };

  const closeModalHandler = () => {
    sessionStorage.setItem("firstModalAgree", "1");
    setVisible(false);
  };

  useEffect(() => {
    isOldUserCheck();
  }, [authenticated]);

  return (
    <Dialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      animation="zoom"
      maskAnimation="fade"
      title={t("title")}
      forceRender={false}
      className="default-modal"
    >
      <div className="default-modal__content">
        <p>{t("text1")}</p>
        <ul>
          <li>{t("list1.li1")}</li>
          <li>{t("list1.li2")}</li>
          <li>{t("list1.li3")}</li>
        </ul>
        <h3>{t("subTitle1")}</h3>
        <b>{t("strong1")}</b>
        <ul>
          <li>{t("list2.li1")}</li>
        </ul>
        <b>{t("strong2")}</b>
        <ul>
          <li>{t("list3.li1")}</li>
          <li>
            <Trans
              components={{
                a: <a href="mailto:info@buycoin.online" className="default-link" />,
              }}
            >
              {t("list3.li2", { email: "info@buycoin.online" })}
            </Trans>
          </li>
        </ul>
        <b>{t("strong3")}</b>
        <ul>
          <li>{t("list4.li1")}</li>
        </ul>
        <h3>{t("subTitle2")}</h3>
        <p>
          <Trans
            components={{
              a: <a href="mailto:info@buycoin.online" className="default-link" />,
            }}
          >
            {t("text2", { email: "info@buycoin.online" })}
          </Trans>
        </p>
        <p>{t("text3")}</p>
      </div>
      <div className="default-modal__footer">
        <StyledButton color="main" border onClick={closeModalHandler}>
          {t("okButton")}
        </StyledButton>
      </div>
    </Dialog>
  );
};

export default OldUserModal;
