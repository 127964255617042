import styled from "styled-components";

import { StyledInputGroup, StyledLabel } from "../inputGroup/styledInputGroup";
import { StyledButton } from "../../styles/styledButton";
import { StyledDatepicker, StyledDatepickerLabel } from "../../styles/styledDatepicker";
import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";

export const StyledFilterWrapperOld = styled.div`
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  position: relative;
`;

export const StyledFilterHead = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

export const StyledTotalItems = styled.div`
  p {
    color: ${({ theme }) => theme.neutral.second};
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (max-width: 768px) {
    p {
      text-align: left;
    }
  }
`;

export const StyledFilterWrapper = styled.div`
  ${({ pt }) => pt && `padding-top: ${pt}px`};
  ${({ pb }) => pb && `padding-bottom: ${pb}px`};
  display: flex;
  justify-content: ${({ buttonAlign }) => buttonAlign ?? "flex-end"};
  position: relative;

  .apply-button {
    min-width: 125px;
    border-color: transparent;

    &:hover {
      color: ${({ theme }) => theme.primary.first};
      background-color: ${({ theme }) => theme.primary.third};
    }

    &_active:hover {
      color: ${({ theme }) => theme.neutral.white};
      background-color: ${({ theme }) => theme.primary.first};
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    .apply-button {
      min-width: 125px;
      width: 100%;
      color: ${({ theme }) => theme.primary.first};
      background-color: ${({ theme }) => theme.primary.third};
      border-color: transparent;
    }
  }
`;

export const StyledFilterBody = styled.div`
  height: 100%;
  padding: 24px 24px 0;
  direction: ltr;
  background-color: ${({ theme }) => theme.filter.backgroundColor};
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 40px 0 0 0;

  &:not(:hover)::-webkit-scrollbar-thumb {
    background: transparent;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    background-color: ${({ theme }) => theme.filter.backgroundColorHover};
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    width: 1px;
    border: 1px solid transparent;
    background-color: ${({ theme }) => theme.primary.second};
    border-radius: 2px;
  }

  @media screen and (max-width: 480px) {
    padding: 16px 16px 0;
    border-radius: 0;
  }
`;

export const StyledFilterHeader = styled.div`
  padding: 0 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon-ex-input-close {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.filter.iconColor};
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: ${({ theme }) => theme.neutral.first};
    }
  }

  h3 {
    color: ${({ theme }) => theme.neutral.second};
    font-size: 20px;
    font-weight: 400;
  }
`;

export const StyledFilterFooter = styled.div`
  margin-top: 20px;
  padding: 8px 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background-color: ${({ theme }) => theme.filter.backgroundColor};
  position: sticky;
  bottom: 0;

  button {
    max-width: 50%;
    width: 50%;
    position: relative;

    span:before {
      font-size: 22px;
      color: ${({ theme }) => theme.primary.first};
    }

    .counter {
      min-width: 20px;
      padding: 2px;
      color: #ffffff;
      font-size: 10px;
      line-height: 10px;
      border-radius: 3px;
      background-color: ${({ theme }) => theme.filter.iconColor};
      position: absolute;
      top: -5px;
      left: -5px;
    }
  }
`;

export const StyledFilterGroup = styled.div`
  margin: 6px 0;

  &:last-child {
    padding-top: 20px;
    border: none;
  }

  .filter-group {
    &__title {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
    }
  }
`;

export const StyledFilterGroupHead = styled.div`
  padding: 12px 16px;
  color: ${({ theme }) => theme.neutral.second};
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border-radius: 10px;
  transition: all 0.3s ease;

  [class^="icon-ex-"],
  [class*=" icon-ex-"],
  .icon-ex-filter-by,
  .icon-chevron-up,
  .icon-chevron-down {
    width: 20px;
    height: 20px;
    color: ${({ theme }) => theme.filter.iconColor};
    font-size: 20px;
  }

  ${({ isOpen, theme }) =>
    isOpen &&
    `
    background-color: ${theme.filter.backgroundColorHover};
  `};

  &:hover {
    background-color: ${({ theme }) => theme.filter.backgroundColorHover};
  }

  span:before {
    font-size: 20px;
  }
`;

export const StyledFilterGroupBody = styled.div`
  ${({ isOpen }) => !isOpen && "height: 0"};
  ${({ isOpen }) => (!isOpen ? "display: none" : "display: flex")};
  width: 100%;
  margin-bottom: 18px;
  padding: 12px 16px;
  flex-wrap: wrap;
  gap: 16px;

  ${StyledSelect},
  ${StyledInputGroup},
  ${StyledDatepicker} {
    width: 100%;
  }

  ${StyledSelectLabel},
  ${StyledLabel},
  ${StyledDatepickerLabel} {
    font-weight: 500;
  }

  ${StyledInputGroup} {
    width: 100%;
    margin-bottom: 0 !important;
    justify-content: flex-start;
  }
`;

export const StyledFilterList = styled.div`
  padding-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  @media screen and (max-width: 768px) {
    --containerPadding: 14px;
    width: calc(100% + var(--containerPadding));
    //padding-top: 16px;
    padding-right: 14px;
    gap: 8px;
    flex-wrap: unset;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledFilterListItem = styled.div`
  max-width: 200px;
  padding: 5px 10px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.filter.borderColor};
  border-radius: 23px;
  outline: none;
  transition: all 0.3s ease;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => theme.filter.backgroundColorHover};
  }

  ${({ clearAll, theme }) =>
    clearAll &&
    `
    border-color: ${theme.danger.first};
    
    &:hover {
      background-color: ${theme.danger.second};
    }
  `};

  .key,
  .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .value {
    padding-left: 2px;
  }

  .remove {
    padding-left: 6px;
    width: 8px;
    height: 8px;
    color: ${({ theme }) => theme.danger.first};
    font-size: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

export const StyledFilterRangeButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  ${StyledButton} {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 14px;
    border-radius: 23px;
  }
`;
