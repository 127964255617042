import Checkbox from "rc-checkbox";
import { useState } from "react";
import { Trans } from "react-i18next";

import { regex } from "../../../../utils/consts";
import CustomLink from "../../../elements/customLink/CustomLink";
import InputGroup from "../../../elements/inputGroup/InputGroup";
import PasswordValidator from "../../../elements/password/PasswordValidator";
import LoadButton from "../../../elements/spinner/ButtonSpinner";
import { StyledButton } from "../../../styles/styledButton";
import { StyledCheckboxLabel, StyledCheckboxWrapper } from "../../../styles/styledCheckbox";
import { StyledMainTitle } from "../../../styles/styledTitle";
import LoginGoogleForm from "../../login/LoginGoogleForm";
import { StyledAuthActions, StyledAuthAgreeCheckbox, StyledAuthTextSeparator } from "../../styledAuth";
import { StyledParagraph } from "../../../styles/styledTypography";

const RegistrationFormStep = ({
  handleSubmit,
  onChangeInput,
  agree,
  setAgree,
  errors,
  firstname,
  lastname,
  email,
  password,
  loading,
  t,
  authData,
  setAuthData,
}) => {
  const [passwordErrors, setPasswordErrors] = useState(null);

  return (
    <>
      <StyledMainTitle mb="34" size="36">
        {t("registration.title")}
      </StyledMainTitle>
      <form onSubmit={handleSubmit}>
        <InputGroup
          id="firstname"
          type="text"
          label={t("registration.form.firstname")}
          placeholder={t("registration.form.firstnamePlaceholder")}
          name="firstname"
          data-regex={regex.NAME}
          autoComplete="off"
          onChange={onChangeInput}
          required
          error={errors?.["firstname"]}
          value={firstname}
          maxLength={100}
          icon="input-usename"
        />
        <InputGroup
          id="lastname"
          type="text"
          label={t("registration.form.lastname")}
          placeholder={t("registration.form.lastnamePlaceholder")}
          data-regex={regex.NAME}
          name="lastname"
          autoComplete="off"
          required
          error={errors?.["lastname"]}
          onChange={onChangeInput}
          value={lastname}
          maxLength={100}
          icon="input-usename"
        />
        <InputGroup
          id="email"
          type="email"
          label="E-mail"
          placeholder={t("registration.form.emailPlaceholder")}
          name="email"
          autoComplete="off"
          data-regex={regex.EMAIL}
          required
          error={errors?.["email"]}
          onChange={onChangeInput}
          value={email}
          maxLength={150}
          icon="input-email"
        />
        <InputGroup
          id="password"
          type="password"
          label={t("registration.form.password")}
          placeholder={t("registration.form.passwordPlaceholder")}
          name="password"
          autoComplete="off"
          data-regex={regex.PASSWORD}
          required
          error={errors?.["password"]}
          handleChange={onChangeInput}
          value={password}
        />
        <PasswordValidator
          password={password}
          passwordErrors={passwordErrors}
          setPasswordErrors={setPasswordErrors}
          t={t}
        />
        <StyledAuthAgreeCheckbox>
          <StyledCheckboxWrapper>
            <Checkbox
              id="agree"
              className="default-checkbox"
              onClick={() => setAgree(!agree)}
              name="agree"
              value={agree}
              required="required"
              autoComplete="off"
              checked={agree}
            />
            <StyledCheckboxLabel htmlFor="agree" padding="0 8px 0">
              <Trans
                components={{
                  termsAndConditions: <a href="#" className="default-link" target="_blank" />,
                  privacyPolicy: <a href="#" className="default-link" target="_blank" />,
                }}
                t={t}
                i18nKey="registration.confirm"
              />
            </StyledCheckboxLabel>
          </StyledCheckboxWrapper>
        </StyledAuthAgreeCheckbox>
        <StyledAuthActions>
          {!loading ? (
            <StyledButton color="main" type="submit" width="100%" disabled={!agree}>
              {t("registration.form.submit")}
            </StyledButton>
          ) : (
            <LoadButton
              width="100%"
              color="main"
              text={t("registration.form.submit")}
            />
          )}
        </StyledAuthActions>
      </form>
      <StyledAuthTextSeparator>{t("registration.fromSocial")}</StyledAuthTextSeparator>
      <LoginGoogleForm authData={authData} setAuthData={setAuthData} t={t} />
      <StyledParagraph className="sub-text">
        <Trans components={{ CustomLink: <CustomLink /> }} t={t} i18nKey="registration.loginLink" />
      </StyledParagraph>
    </>
  );
};

export default RegistrationFormStep;
