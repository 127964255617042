import styled from "styled-components";
import { StyledParagraph } from "../../styles/styledTypography";

export const StyledExchangerAuthNewCode = styled.div`
  padding: 16px 0;
  display: flex;

  ${StyledParagraph} {
    color: ${({ theme }) => theme.neutral.six};
  }

  .send-button {
    color: ${({ theme }) => theme.primary.first};
    text-decoration: underline;
    outline: none;
    border: none;
  }
`;