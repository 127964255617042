import { createAsyncThunk } from "@reduxjs/toolkit";
import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const handleForgotPassword = createAsyncThunk(
  "userSlice/handleForgotPassword",
  async (userEmail, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api/users/forgot-password", { email: userEmail });

      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response?.data?.data);
    }
  }
);

const handleCheckTwoFaIsActive = createAsyncThunk(
  "userSlice/checkTwoFaIsActive",
  async (_, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState();
      const userId = currentState.user.user.userId;

      const response = await axiosInstance(
        `/api/users/authenticator-secret-is-active/${userId}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        status: response.status,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const handleGetAuthenticatorSecretUser = createAsyncThunk(
  "userSlice/handleGetAuthenticatorSecretUser",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance(
        "/api/users/authenticator-secret",
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const handleSetAuthenticatorSecretUser = createAsyncThunk(
  "userSlice/handleSetAuthenticatorSecretUser",
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/users/authenticator-secret-add",
        data,
        userAuthenticationConfig()
      );
      closableNotification(t("alerts.authenticatorSecretAdded"), "success");
      return {
        status: response.status,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const handleRemoveAuthenticatorSecretUser = createAsyncThunk(
  "userSlice/handleRemoveAuthenticatorSecretUser",
  async ({ data, callback }, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState();
      const userId = currentState.user.user.userId;

      const response = await axiosInstance.put(
        `/api/users/authenticator-secret-remove/${userId}`,
        data,
        userAuthenticationConfig()
      );
      closableNotification(t("alerts.authenticatorSecretRemoved"), "success");
      return {
        status: response.status,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const handleChangePassword = createAsyncThunk(
  "userSlice/handleChangePassword",
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        "/api/users/change-password",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const handleRecoverPassword = createAsyncThunk(
  "userSlice/handleRecoverPassword",
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        "/api/users/recover-password",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const handleGetPersonalData = createAsyncThunk("userSlice/getPersonalData", async (_, thunkAPI) => {
  try {
    const currentState = thunkAPI.getState();
    const userId = currentState.user.user.userId;

    const response = await axiosInstance(
      `/api/exchanger-users/${userId}/personal-data`,
      userAuthenticationConfig()
    );

    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data);
  }
});

export {
  handleChangePassword,
  handleCheckTwoFaIsActive,
  handleForgotPassword,
  handleGetAuthenticatorSecretUser,
  handleGetPersonalData,
  handleRecoverPassword,
  handleRemoveAuthenticatorSecretUser,
  handleSetAuthenticatorSecretUser,
};
