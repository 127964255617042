import { createSlice } from "@reduxjs/toolkit";

import {
  changeExchangerMarkupReducer,
  changeMerchantExchangerMarkupReducer,
  changeOurMarkupReducer,
  fetchBaseCurrencyRatesReducer,
  fetchExchangerRatesReducer,
  fetchMainExchangerRatesReducer,
  fetchMerchantExchangerRatesReducer,
} from "./ratesReducers";

const initialState = {
  rates: [],
  baseCurrenciesRates: [],
  exchangerRates: [],
  error: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
  supportLoading: false,
  itemLoadingState: {
    ourMarkupPurchase: false,
    ourMarkupSelling: false,
    exchangerMarkupPurchase: false,
    exchangerMarkupSelling: false,
  },
  editingItem: null,
};

const ratesSlice = createSlice({
  name: "ratesSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateRates: (state) => {
      state.rates = [];
      state.baseCurrenciesRates = [];
      state.exchangerRates = [];
      state.error = null;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    changeExchangerMarkupReducer(builder);
    fetchMainExchangerRatesReducer(builder);
    fetchBaseCurrencyRatesReducer(builder);
    fetchExchangerRatesReducer(builder);
    changeOurMarkupReducer(builder);
    fetchMerchantExchangerRatesReducer(builder);
    changeMerchantExchangerMarkupReducer(builder);
  },
});

export const { setPaginationInfo, clearStateRates } = ratesSlice.actions;

export default ratesSlice.reducer;
