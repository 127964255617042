import { useSelector } from "react-redux";

import { useMedia } from "../../../utils/customHooks/useMedia";
import { StyledSpan } from "../../styles/styledTypography";
import { StyledLogoIcon, StyledLogoWrapper } from "./styledLogo";

import defaultExchangerLogo from "../../../assets/images/exhanger-logo.svg";
import defaultExchangerMobileLogo from "../../../assets/images/exhanger-logo_mobile.svg";

const Logo = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const siteUrl = window.location.origin;

  const path = baseURL && baseURL !== siteUrl ? "/" : process.env.REACT_APP_LANDING_DOMAIN;

  const { siteConfig } = useSelector((store) => store.theme);

  const isMobile = useMedia("(max-width: 768px)");

  // const mobileLogo = siteConfig?.mobileLogo ?? siteConfig?.logo;

  const exchangerLogo = siteConfig?.logo ?? defaultExchangerLogo;

  const exchangerMobileLogo = siteConfig?.mobileLogo ? siteConfig?.mobileLogo : siteConfig?.logo ?? defaultExchangerMobileLogo;

  return (
    <a href={path}>
      <StyledLogoWrapper>
        {baseURL && baseURL !== siteUrl
          ? <StyledLogoIcon>
            <img
              src={isMobile ? exchangerMobileLogo : exchangerLogo}
              alt="exchanger-logo"
            />
          </StyledLogoIcon>
          : <StyledSpan fontSize="24" fontWeight="700">
            {siteConfig?.siteName}
          </StyledSpan>
        }
      </StyledLogoWrapper>
    </a>
  );
};

export default Logo;
