import { createSlice } from "@reduxjs/toolkit";

import {
  addToBlackListWalletReducer,
  deleteWalletFromBlacklistReducer,
  fetchBlackListWalletsReducer
} from "./blackListWalletReducers";

const initialState = {
  blackListWallets: [],
  error: null,
  loading: true,
  addToBlackListWallets: {
    loading: false
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
};

const blackListWalletSlice = createSlice({
  name: "blackListWalletSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateBlackListWallet: (state) => {
      state.blackListWallets = [];
      state.exchangerRates = [];
      state.error = null;
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    fetchBlackListWalletsReducer(builder);
    addToBlackListWalletReducer(builder);
    deleteWalletFromBlacklistReducer(builder);
  },
});

export const { setPaginationInfo, clearStateBlackListWallet } = blackListWalletSlice.actions;

export default blackListWalletSlice.reducer;
