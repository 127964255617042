import styled from "styled-components";
import { StyledCustomCopyToClipboard } from "../copyToClipboard/StyledCustomCopyToClipboard";

export const StyledTwoFaForm = styled.form`
  max-width: 360px;
  width: 100%;
  margin: 0 auto;
`;

export const StyledTwoFaActiveDescription = styled.div`
  margin-bottom: 36px;

  @media screen and (max-width: 576px) {
    margin-bottom: 24px;
  }
`;

export const StyledFirstLoginDataWrapper = styled.div`
  max-width: 360px;
  width: 100%;
  padding-bottom: 16px;

  a {
    color: ${({ theme }) => theme.primary.first};
  }

  img {
    width: 160px;
    height: 160px;
    margin: 0 auto;
    padding: 8px;
    display: block;
    border: 1px solid ${({ theme }) => theme.neutral.black};
    border-radius: 16px;
  }

  ${StyledCustomCopyToClipboard} {
    margin: 16px 0;
    padding: 16px 0;
    background-color: ${({ theme }) => theme.primary.fifth};
    border-radius: 16px;

    .copy-block {
      color: ${({ theme }) => theme.info.first};
      font-size: 24px;
      font-weight: 500;
      line-height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
    }

    .icon-ex-copy {
      padding-left: 0;
      font-size: 16px;
      color: ${({ theme }) => theme.info.first};
    }

    @media screen and (max-width: 576px) {
      border-radius: 12px;

      .copy-block {
        font-size: 18px;
        gap: 12px;
      }
    }
  }
`;
