import ExchangerAuthContainer from "../../components/auth/exchangerAuth/ExchangerAuthContainer";
import LoginContainer from "../../components/auth/login/LoginContainer";

const LoginPage = () => {
  const isMain = process.env.REACT_APP_BASE_URL === window.location.origin;
  if (isMain) {
    return <LoginContainer />;
  }

  return <ExchangerAuthContainer />;
};

export default LoginPage;
