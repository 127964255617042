import { createSlice } from "@reduxjs/toolkit";

import { waitTimer } from "../../utils/consts";
import {
  authenticationRequestReducer,
  authenticationWithTokenReducer,
  checkAuthCodeReducer,
  getAuthCodeExpirationReducer,
  getAuthTokenReducer,
  getEndpointPermissionsReducer,
  handleRegistrationReducer,
  handleSetTwoFaReducer,
  recreateAuthCodeReducer,
  recreateAuthCodeWithTokenReducer,
  refreshTokenReducer,
} from "./authReducers";

const initialState = {
  token: localStorage.getItem("token"),
  authenticated: localStorage.getItem("token"),
  loading: false,
  userTwoFa: false,
  userAuthCode: false,
  errors: null,
  userData: null,
  firstLoginData: null,
  userId: null,
  timeDifference: null,
  codeExpiration: waitTimer.CODE_EXPIRED,
  attempts: waitTimer.ATTEMPTS,
  userDataTwoFa: null,
  isExchangerClient: false,
  endpointPermissions: localStorage.getItem("endpoints"),
  exchangerAuth: {
    clientLoginToken: null,
    datetime: null,
    email: null,
    attempts: null,
  },
};

const authSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    handleLogout: (state) => {
      state.authenticated = false;
      localStorage.removeItem("clientId");
      localStorage.removeItem("token");
      localStorage.removeItem("endpoints");
      window.dispatchEvent(new CustomEvent("userLoggedOut"));
      state.endpointPermissions = null;
      state.isExchangerClient = false;
      state.exchangerAuth = {
        clientLoginToken: null,
        datetime: null,
        email: null,
      };
    },
    clearExchangerAuth: (state) => {
      state.exchangerAuth = {
        clientLoginToken: null,
        datetime: null,
        email: null,
      };
    },
    unsetAuthData: (state) => {
      state.userTwoFa = false;
    },
    setAuthErrors: (state, action) => {
      state.errors = action.payload;
    },
    removeAuthErrors: (state, action) => {
      if (state.errors?.[action.payload]) {
        delete state.errors[action.payload];
      }
    },
    clearStore: (state) => {
      state.userTwoFa = false;
      state.userAuthCode = false;
      state.firstLoginData = null;
      state.userId = null;
    },
    setAuthenticated: (state) => {
      state.authenticated = true;
    },
  },
  extraReducers: (builder) => {
    authenticationRequestReducer(builder);
    handleRegistrationReducer(builder);
    checkAuthCodeReducer(builder);
    handleSetTwoFaReducer(builder);
    getAuthCodeExpirationReducer(builder);
    recreateAuthCodeReducer(builder);
    refreshTokenReducer(builder);
    getEndpointPermissionsReducer(builder);
    getAuthTokenReducer(builder);
    authenticationWithTokenReducer(builder);
    recreateAuthCodeWithTokenReducer(builder);
  },
});

export const {
  clearStore,
  handleLogout,
  unsetAuthData,
  setAuthErrors,
  removeAuthErrors,
  setAuthenticated,
  clearExchangerAuth,
} = authSlice.actions;
export default authSlice.reducer;
