import { typeAccountRoutes } from "../utils/consts";
import { permissionUserEndpoints } from "../utils/groupEndpoints/exchanger/user";
import { groupPermissionEndpoints } from "../utils/groupEndpoints/groupPermissionEndpoints";
import routes from "./routes";

const userRoutes = [
  {
    path: `${typeAccountRoutes.CRM}/page-not-found`,
    pathComponentPage: "/pageNotFound/PageNotFound",
  },
  // {
  //   path: `${typeAccountRoutes.CRM}/dashboard`,
  //   pathComponentPage: "/dashboard/DashboardPage",
  //   isSuspense: false
  // },
  {
    path: `${typeAccountRoutes.CRM}/settings`,
    permission: [
      groupPermissionEndpoints.API_USERS_GET_ITEM,
      groupPermissionEndpoints.API_USERS_CHANGE_PASSWORD,
      groupPermissionEndpoints.GET_EXCHANGER_AUTHENTICATOR_SECRET_IS_ACTIVE,
    ],
    pathComponentPage: "/account/AccountSettingsPage.jsx",
  },
  {
    path: `${typeAccountRoutes.CRM}/users`,
    permission: [
      groupPermissionEndpoints.API_USERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_USERS_GET_COLLECTION,
    ],
    pathComponentPage: "/users/UsersPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/users-details/:id`,
    pathComponentPage: "/users/UsersDetailsPage",
    permission: [
      groupPermissionEndpoints.API_USERS_GET_ITEM,
      groupPermissionEndpoints.API_EXCHANGER_USERS_GET_ITEM,
    ],
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/exchanger`,
    pathComponentPage: "/exchanger/ExchangerPage",
    permission: [
      groupPermissionEndpoints.API_EXCHANGERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGERS_POST_COLLECTION,
    ],
  },
  {
    path: `${typeAccountRoutes.CRM}/exchanger/:id`,
    pathComponentPage: "/exchanger/ExchangerDetailsPage",
    permission: [groupPermissionEndpoints.API_EXCHANGERS_GET_ITEM],
  },
  {
    path: `${typeAccountRoutes.CRM}/rates`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_RATES_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_RATES_CHANGE_OUR_MARKUP_ITEM,
      groupPermissionEndpoints.API_EXCHANGER_RATES_GET_ITEM,
      groupPermissionEndpoints.GET_EXCHANGER_RATES,
      groupPermissionEndpoints.API_GET_EXCHANGER_RATES_COLLECTION,
    ],
    pathComponentPage: "/rates/RatesPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/services`,
    permission: [groupPermissionEndpoints.API_SERVICES_GET_COLLECTION],
    pathComponentPage: "/services/ServicePage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/payment-systems`,
    permission: [
      groupPermissionEndpoints.API_PAYMENT_SYSTEMS_GET_COLLECTION,
      groupPermissionEndpoints.API_PAIR_UNITS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_PAIR_UNITS_GET_COLLECTION,
    ],
    pathComponentPage: "/paymentSystems/PaymentSystemsPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/pairs`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_PAIRS_GET_COLLECTION],
    pathComponentPage: "/pairs/PairsPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/servers`,
    permission: [groupPermissionEndpoints.API_SERVERS_GET_COLLECTION],
    pathComponentPage: "/servers/ServersPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/database-exchanger`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_DATABASE_CONNECTIONS_GET_COLLECTION],
    pathComponentPage: "/dbExchangerConnections/DbExchangerConnectionsPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/balances`,
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_BALANCES_GET_COLLECTION,
      groupPermissionEndpoints.API_BALANCES_GET_COLLECTION,
    ],
    pathComponentPage: "/balances/BalancesPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/reports`,
    permission: [
      groupPermissionEndpoints.GET_EXCHANGER_ORDERS_REPORT,
      groupPermissionEndpoints.API_EXCHANGER_TRAFFIC_LINKS_GET_COLLECTION,
    ],
    pathComponentPage: "/reports/ReportsPage",
    isSuspense: false,
  },
  {
    path: `${typeAccountRoutes.CRM}/reports/traffic/:id`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_TRAFFIC_LINKS_GET_ITEM],
    pathComponentPage: "/trafficDetails/TrafficDetailsPage",
  },
  {
    path: `${typeAccountRoutes.CRM}/system-commands`,
    permission: [
      groupPermissionEndpoints.FILL_PAIR_UNITS,
      groupPermissionEndpoints.FILL_ENDPOINTS,
      groupPermissionEndpoints.FILL_GROUP_AND_ENDPOINT_PERMISSIONS,
      groupPermissionEndpoints.CHECK_DIFFERENCE_BETWEEN_ENDPOINTS_AND_GROUP_ENDPOINTS,
      groupPermissionEndpoints.UPDATE_DATABASE_SCHEMA,
      groupPermissionEndpoints.CHECK_DIFFERENCE_BETWEEN_ENDPOINTS_AND_GROUP_ENDPOINTS,
      groupPermissionEndpoints.UPDATE_DATABASE_SCHEMA_FOR_ALL_EXCHANGERS,
      groupPermissionEndpoints.FILL_BASE_CURRENCY_RATE_SERVICE,
    ],
    pathComponentPage: "/systemCommands/SystemCommandsPage",
  },
  {
    path: `${typeAccountRoutes.CRM}/orders`,
    pathComponentPage: "/orders/OrdersPage",
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_ITEM,
    ],
  },
  {
    path: `${typeAccountRoutes.CRM}/orders-details/:id`,
    pathComponentPage: "/orders/OrderDetailsPage",
    permission: [groupPermissionEndpoints.API_EXCHANGER_ORDERS_GET_ITEM],
  },
  {
    path: `${typeAccountRoutes.CRM}/transactions`,
    pathComponentPage: "/transactions/TransactionsPage",
    permission: [
      groupPermissionEndpoints.CREATE_PAYMENT,
      groupPermissionEndpoints.CREATE_PAYOUT,
      groupPermissionEndpoints.GET_EXCHANGER_PAIR_UNIT,
      groupPermissionEndpoints.GET_INVOICES_LIST,
      groupPermissionEndpoints.GET_INVOICE_DETAILS,
    ],
  },
  {
    path: `${typeAccountRoutes.CRM}/transaction-details/:id`,
    pathComponentPage: "/transactions/TransactionDetailsPage",
    permission: [groupPermissionEndpoints.GET_INVOICE_DETAILS],
  },
  {
    path: `${typeAccountRoutes.CRM}/exchanger/:exchangerId/transaction-details/:id`,
    pathComponentPage: "/exchanger/ExchangerTransactionDetailsPage",
    permission: [groupPermissionEndpoints.GET_INVOICE_DETAILS],
    // perform:
  },
  {
    path: `${typeAccountRoutes.CRM}/widget`,
    pathComponentPage: "/referralWidget/ReferralWidgetPage",
    permission: [groupPermissionEndpoints.API_EXCHANGER_WIDGETS_GET_COLLECTION],
  },
  {
    path: `${typeAccountRoutes.CRM}/widget/:id`,
    pathComponentPage: "/referralWidget/ReferralWidgetDetailsPage",
    permission: [groupPermissionEndpoints.API_EXCHANGER_WIDGETS_GET_ITEM],
  },
  {
    path: `${typeAccountRoutes.CRM}/referral`,
    pathComponentPage: "/referralSystem/ReferralSystemPage",
    permission: [permissionUserEndpoints.GET_EXCHANGER_REFERRAL_TOKEN],
    checkFullAccess: true,
  },
  {
    path: `${typeAccountRoutes.CRM}/documents`,
    pathComponentPage: "/documents/DocumentsPage",
    permission: [
      groupPermissionEndpoints.GET_EXCHANGER_USER_VERIFICATIONS,
      groupPermissionEndpoints.POST_EXCHANGER_USER_VERIFICATION,
    ],
  },
  {
    path: `${typeAccountRoutes.CRM}/saved-requisites`,
    pathComponentPage: "/savedRequisites/SavedRequisitesPage",
    permission: [permissionUserEndpoints.GET_EXCHANGER_USER_REQUISITES],
    checkFullAccess: true,
  },
  {
    path: `${typeAccountRoutes.CRM}/balance`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_BALANCES],
    pathComponentPage: "/userBalance/BalancePage",
  },
  {
    path: `${typeAccountRoutes.CRM}/withdrawal-requisitions`,
    permission: [groupPermissionEndpoints.API_EXCHANGER_WITHDRAWAL_REQUISITION_GET_COLLECTION],
    pathComponentPage: "/withdrawalRequisitions/WithdrawalRequisitionsPage",
    checkFullAccess: true,
  },
  {
    path: `${typeAccountRoutes.CRM}/black-list-wallets`,
    pathComponentPage: "/blacklistWallet/BlacklistWalletPage",
    permission: [
      groupPermissionEndpoints.API_EXCHANGER_BLACK_LIST_WALLETS_GET_COLLECTION,
      groupPermissionEndpoints.API_EXCHANGER_BLACK_LIST_WALLETS_GET_ITEM,
    ],
  },
  {
    path: `${typeAccountRoutes.CRM}/logs`,
    pathComponentPage: "/logs/LogsPage",
    permission: [groupPermissionEndpoints.GET_EXCHANGER_LOGS],
  },
];

const userRoutesConcat = routes.concat(userRoutes);

export default userRoutesConcat;
