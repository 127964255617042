export const permissionMainSystemAdminEndpoints = {
  API_EXCHANGER_DATABASE_CONNECTIONS_GET_COLLECTION: {
    name: "api_exchanger_database_connections_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_DATABASE_CONNECTIONS_GET_ITEM: {
    name: "api_exchanger_database_connections_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  // API_EXCHANGER_DATABASE_CONNECTIONS_CHANGE_STATUS_ITEM: {
  //   name: "api_exchanger_database_connections_change_status_item",
  //   hasFullAccess: true,
  //   isTwoFaRequired: false,
  // },
  API_SERVERS_GET_COLLECTION: {
    name: "api_servers_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVERS_POST_COLLECTION: {
    name: "api_servers_post_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVERS_GET_ITEM: {
    name: "api_servers_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVERS_PUT_ITEM: {
    name: "api_servers_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_USERS_CHANGE_PASSWORD: {
    name: "put_users_change_password",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  REFRESH_TOKEN: {
    name: "refresh_token",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
};
