import axios from "axios";

const axiosInstance = axios.create();

const setupAxiosInterceptors = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const siteUrl = window.location.origin;

  axiosInstance.interceptors.request.use(
    (config) => {
      if (config.url.startsWith("/api")) {
        config.url = config.url.replace("/api", "/x/api");

        if (
          baseURL &&
          baseURL !== siteUrl &&
          config.url.startsWith("/x/api") &&
          !config.url.includes("/x/api/exchanger") &&
          !config.url.startsWith("/x/api/main-exchanger-balance")
        ) {
          config.url = config.url.replace("/x/api/", "/x/api/exchanger-");
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
};

setupAxiosInterceptors();

export default axiosInstance;
