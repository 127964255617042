import { createSlice } from "@reduxjs/toolkit";

import {
  changeExchangerConnectionsStatusReducer,
  fetchExchangerConnectionsReducer,
} from "./dbExchangerConnectionsReducer";

const initialState = {
  exchangerConnections: [],
  errors: null,
  loading: true,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
};

const dbExchangerConnectionsSlice = createSlice({
  name: "dbExchangerConnectionsSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateConnections: (state) => {
      state.exchangerConnections = [];
      state.loading = true;
      state.errors = null;
    },
  },
  extraReducers: (builder) => {
    fetchExchangerConnectionsReducer(builder);
    changeExchangerConnectionsStatusReducer(builder);
  },
});

export const { setPaginationInfo, clearStateConnections } = dbExchangerConnectionsSlice.actions;

export default dbExchangerConnectionsSlice.reducer;
