import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";

const createPayment = createAsyncThunk(
  "transactionsSlice/createPayment",
  async ({ data, redirectCallback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/create-payment",
        data,
        userAuthenticationConfig()
      );

      redirectCallback && redirectCallback(response.data.id);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const createPayout = createAsyncThunk(
  "paymentSlice/createPayout",
  async ({ data, redirectCallback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/create-payout",
        data,
        userAuthenticationConfig()
      );

      redirectCallback && redirectCallback(response.data.id);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchPaymentTransactionAttributes = createAsyncThunk(
  "transactionsSlice/fetchPaymentTransactionAttributes",
  async (paymentPairUnitId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/transaction-attributes?paymentPairUnitId=${paymentPairUnitId}`,
        userAuthenticationConfig()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const fetchPayoutTransactionAttributes = createAsyncThunk(
  "transactionsSlice/fetchPayoutTransactionAttributes",
  async (payoutPairUnitId, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/transaction-attributes?payoutPairUnitId=${payoutPairUnitId}`,
        userAuthenticationConfig()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const fetchExchangerPairUnitData = createAsyncThunk(
  "transactionsSlice/fetchExchangerPairUnitData",
  async (data, thunkAPI) => {
    try {
      const { currencyId, serviceId, paymentSystemId, type } = data;

      const response = await axiosInstance(
        `/api/get-exchanger-pair-unit?currencyId=${currencyId}&serviceId=${serviceId}&paymentSystemId=${paymentSystemId}&type=${type}`,
        userAuthenticationConfig()
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const fetchTransactionsList = createAsyncThunk(
  "transactionsSlice/fetchTransactionsList",
  async ({ filterUrl, type }, thunkAPI) => {
    try {
      const response = await axiosInstance(`/api/invoices${filterUrl}`, userAuthenticationConfig());

      return {
        data: response.data.data,
        type,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const fetchAvailableServices = createAsyncThunk(
  "transactionsSlice/fetchAvailableServices",
  async (type, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/get-available-services?type=${type}`,
        userAuthenticationConfig()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data?.errors);
    }
  }
);

const fetchAvailableCurrencies = createAsyncThunk(
  "transactionsSlice/fetchAvailableCurrencies",
  async ({ type, service }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/get-available-currencies?type=${type}&service=${service}`,
        userAuthenticationConfig()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data?.errors);
    }
  }
);

const fetchAvailablePaymentSystems = createAsyncThunk(
  "transactionsSlice/fetchAvailablePaymentSystems",
  async ({ type, currency }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/get-available-payment-systems?type=${type}&currency=${currency}`,
        userAuthenticationConfig()
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data?.errors);
    }
  }
);

export {
  createPayment,
  createPayout,
  fetchAvailableCurrencies,
  fetchAvailablePaymentSystems,
  fetchAvailableServices,
  fetchExchangerPairUnitData,
  fetchPaymentTransactionAttributes,
  fetchPayoutTransactionAttributes,
  fetchTransactionsList,
};
