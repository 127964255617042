import React, { useMemo, useState } from "react";
import { Sidebar } from "react-pro-sidebar";
import { useDispatch, useSelector } from "react-redux";

import { handleCloseSideBar, handleSidebarToggled } from "../../../store/theme/themeSlice";
import { exchangerRoles } from "../../../utils/consts";
import { checkPermissionByName } from "../../../utils/customFunc/checkPermission";
import { useMedia } from "../../../utils/customHooks/useMedia";
import { groupPermissionEndpoints } from "../../../utils/groupEndpoints/groupPermissionEndpoints";
import { StyledSpan } from "../../styles/styledTypography";
import BalanceButton from "../../userBalance/BalanceButton";
import UserInfo from "../../userInfo/UserInfo";
import SidebarLinkList from "./SidebarLinkList";
import {
  StyledSidebar,
  StyledSidebarItem,
  StyledSidebarLink,
  StyledSidebarSiteLinks,
} from "./styledSidebar";

const SidebarContainer = () => {
  const dispatch = useDispatch();

  const { email, roles } = useSelector((store) => store.user.user) || {};
  const { sidebarCollapsed, sidebarToggled } = useSelector((store) => store.theme);
  const { profits, profitLoading } = useSelector((state) => state.userBalance);

  const [animateOpen, setAnimateOpen] = useState(null);

  const isExchanger = process.env.REACT_APP_BASE_URL !== window.location.origin;
  const isUser = roles?.some((role) => role === exchangerRoles.USER);
  const isUserExchangerAdmin = roles?.some((role) => role === exchangerRoles.ADMIN);

  const haveAccess = useMemo(
    () => checkPermissionByName([groupPermissionEndpoints.API_EXCHANGER_BALANCES]),
    []
  );

  const isMobile = useMedia("(max-width: 992px)");
  const extraSmall = useMedia("(max-width: 374px)");

  return (
    <Sidebar
      width={isMobile && !extraSmall ? "325px" : "258px"}
      collapsedWidth="69px"
      className="sidebar-aside"
      breakPoint="lg"
      collapsed={sidebarCollapsed}
      toggled={sidebarToggled}
      onBackdropClick={() => dispatch(handleSidebarToggled())}
    >
      <StyledSidebar>
        <StyledSidebarSiteLinks>
          {isMobile && (
            <>
              {isUser && haveAccess && (
                <BalanceButton profits={profits} profitLoading={profitLoading} setToggled />
              )}
              <UserInfo email={email} isMobile={isMobile} />
            </>
          )}
          <SidebarLinkList
            setAnimateOpen={setAnimateOpen}
            animateOpen={animateOpen}
            isUserExchangerAdmin={isUserExchangerAdmin}
            isExchanger={isExchanger}
          />
        </StyledSidebarSiteLinks>
        <StyledSidebarLink className="sidebar-collapsed">
          <StyledSidebarItem className="sidebar-collapsed__item">
            <StyledSpan
              onClick={() => dispatch(handleCloseSideBar())}
              fontSize="20"
              className={`sidebar-collapsed__btn ${sidebarCollapsed ? "icon-ex-menu-hide-right" : "icon-ex-menu-hide-left"}`}
            />
          </StyledSidebarItem>
        </StyledSidebarLink>
      </StyledSidebar>
    </Sidebar>
  );
};

export default SidebarContainer;
