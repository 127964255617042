import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";

import USDTIcon from "../../assets/images/payment-system-icons/USDT.svg";
import { handleSidebarToggled } from "../../store/theme/themeSlice";
import CustomLink from "../elements/customLink/CustomLink";

const BalanceButton = ({ profits, profitLoading, setToggled }) => {
  const dispatch = useDispatch();

  const getProfitByType = (searchedType) =>
    profits?.find(({ type }) => type === searchedType)?.amount;
  const totalAmount = getProfitByType("actual");

  const handleClick = () => {
    if (setToggled) {
      dispatch(handleSidebarToggled());
    }
  };

  return (
    <CustomLink className="balance header-right__balance" to="/crm/balance" onClick={handleClick}>
      <LazyLoadImage src={USDTIcon} alt="usdt" />
      <p>{profitLoading || !totalAmount ? "0.00" : (+totalAmount).toFixed(2)}</p>
    </CustomLink>
  );
};

export default BalanceButton;
