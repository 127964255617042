import { t } from "i18next";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import {
  assignReferrer,
  banClient,
  changeReferralLifeTimeValue,
  changeUserMonthlyLimit,
  changeUserReferralInfo,
  changeUserWidgetStatus,
  fetchClientDetails,
  fetchClients,
  fetchReferralUsersById,
  fetchWithdrawalRequisitions,
  getUserReferralInfo,
  resetExchangerUserVerification,
} from "./asyncClientsSlice";

const fetchClientsReducer = (builder) => {
  builder.addCase(fetchClients.pending, () => {});
  builder.addCase(fetchClients.fulfilled, (state, action) => {
    state.clients = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchClients.rejected, (state, action) => {
    errorsParser(action.payload);
    state.loading = false;
  });
};

const fetchClientsDetailsReducer = (builder) => {
  builder.addCase(fetchClientDetails.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchClientDetails.fulfilled, (state, { payload: data }) => {
    state.loading = false;
    state.clientDetails = data.data;
    state.referralsUserLife.value = data.data?.exchangerUserInfo?.referralLifeTimeValue;
  });
  builder.addCase(fetchClientDetails.rejected, (state, { payload }) => {
    state.loading = false;
    errorsParser(payload, true);
  });
};

const changeWidgetStatusExchangerReducer = (builder) => {
  builder.addCase(changeUserWidgetStatus.fulfilled, (state, { payload: { data } }) => {
    state.clientDetails = { ...state.clientDetails, widgetStatus: data?.widgetStatus };
    closableNotification(t("alerts.widgetStatusChangedForUser"), "success");
  });
  builder.addCase(changeUserWidgetStatus.rejected, (state, { payload }) => {
    errorsParser(payload);
  });
};

const banClientsReducer = (builder) => {
  builder.addCase(banClient.pending, (state) => {
    state.banLoading = true;
  });
  builder.addCase(banClient.fulfilled, (state, { payload: data }) => {
    state.banLoading = false;
    const newUserData = data.data;

    if (state.clientDetails) {
      state.clientDetails = newUserData;
    }

    if (state.clients.length) {
      state.clients = state.clients.map((item) => {
        if (item.id === newUserData.id) {
          return { ...item, isBanned: newUserData.isBanned };
        }

        return item;
      });
    }
    data?.callback();
    closableNotification(t("alerts.userBanStatusChanged"), "success");
  });
  builder.addCase(banClient.rejected, (state, { payload }) => {
    state.banLoading = false;
    errorsParser(payload);
  });
};

const resetExchangerUserVerificationReducer = (builder) => {
  builder.addCase(resetExchangerUserVerification.pending, (state) => {
    state.verificationLoading = true;
  });
  builder.addCase(resetExchangerUserVerification.fulfilled, (state, action) => {
    state.verificationLoading = false;

    if (Object.keys(state.clientDetails).length) {
      state.clientDetails.exchangerUserVerifications =
        state.clientDetails.exchangerUserVerifications.map((verification) =>
          verification.verificationSchema === action.payload.data.verificationSchema
            ? { ...verification, isVerified: action.payload.data.isVerified }
            : verification
        );
    }

    if (state.clients.length) {
      state.clients = state.clients.map((item) => {
        if (item.email === action.payload.data.exchangerUser.email) {
          item.exchangerUserVerifications = item.exchangerUserVerifications.map((verification) =>
            verification.verificationSchema === action.payload.data.verificationSchema
              ? { ...verification, isVerified: action.payload.data.isVerified }
              : verification
          );
        }

        return item;
      });
    }

    action.payload?.callback();
    closableNotification(t("alerts.userDocumentVerificationReset"), "success");
  });
  builder.addCase(resetExchangerUserVerification.rejected, (state, action) => {
    errorsParser(action.payload);
    state.verificationLoading = false;
  });
};

const getUserReferralInfoReducer = (builder) => {
  builder.addCase(getUserReferralInfo.pending, (state) => {
    state.referralInfo.loading = true;
  });
  builder.addCase(getUserReferralInfo.fulfilled, (state, { payload: data }) => {
    state.referralInfo.loading = false;
    state.referralInfo.data = data.data;
  });
  builder.addCase(getUserReferralInfo.rejected, (state, { payload }) => {
    state.referralInfo.loading = false;
    errorsParser(payload);
  });
};

const changeUserReferralInfoReducer = (builder) => {
  builder.addCase(changeUserReferralInfo.pending, (state) => {
    state.referralInfo.loading = true;
  });
  builder.addCase(changeUserReferralInfo.fulfilled, (state, { payload: data }) => {
    state.referralInfo.loading = false;
    state.referralInfo.data = data.data;
    closableNotification(t("alerts.percentChanged"), "success");
  });
  builder.addCase(changeUserReferralInfo.rejected, (state, { payload }) => {
    state.referralInfo.loading = false;
    errorsParser(payload);
  });
};

const fetchReferralUsersByIdReducer = (builder) => {
  builder.addCase(fetchReferralUsersById.pending, (state) => {
    state.referralUsers.loading = true;
  });
  builder.addCase(fetchReferralUsersById.fulfilled, (state, { payload: data }) => {
    state.referralUsers.loading = false;
    state.referralUsers.list = data.data;
    state.paginationInfo = { ...state.paginationInfo, ...data.paginationInfo };
  });
  builder.addCase(fetchReferralUsersById.rejected, (state, { payload }) => {
    state.referralUsers.loading = false;
    errorsParser(payload);
  });
};

const fetchWithdrawalRequisitionsReducer = (builder) => {
  builder.addCase(fetchWithdrawalRequisitions.pending, (state) => {
    state.withdrawalRequisitions.loading = true;
  });
  builder.addCase(fetchWithdrawalRequisitions.fulfilled, (state, { payload: data }) => {
    state.withdrawalRequisitions.loading = false;
    state.withdrawalRequisitions.list = data.data;
    state.paginationInfo = { ...state.paginationInfo, ...data.paginationInfo };
  });
  builder.addCase(fetchWithdrawalRequisitions.rejected, (state, { payload }) => {
    state.withdrawalRequisitions.loading = false;
    errorsParser(payload);
  });
};

const changeReferralLifeTimeValueReducer = (builder) => {
  builder.addCase(changeReferralLifeTimeValue.pending, (state) => {
    state.referralsUserLife.loading = true;
  });
  builder.addCase(changeReferralLifeTimeValue.fulfilled, (state, { payload: data }) => {
    state.referralsUserLife.loading = false;
    state.referralsUserLife.value = data.data?.exchangerUserInfo?.referralLifeTimeValue;
    closableNotification(t("alerts.valueSuccessfullyUpdated"), "success");
  });
  builder.addCase(changeReferralLifeTimeValue.rejected, (state, { payload }) => {
    state.referralsUserLife.loading = false;
    errorsParser(payload);
  });
};

const assignReferrerReducer = (builder) => {
  builder.addCase(assignReferrer.pending, (state) => {
    state.assignReferrerLoading = true;
  });
  builder.addCase(assignReferrer.fulfilled, (state, action) => {
    state.assignReferrerLoading = false;

    if (!state.clientDetails.exchangerUserInfo.referer) {
      state.clientDetails.exchangerUserInfo.referer = {};
    }
    state.clientDetails.exchangerUserInfo.referer.email = action.payload.data.referrerEmail;

    closableNotification(t("alerts.valueSuccessfullyUpdated"), "success");
  });
  builder.addCase(assignReferrer.rejected, (state, action) => {
    state.assignReferrerLoading = false;
    errorsParser(action.payload);
  });
};

const changeUserMonthlyLimitReducer = (builder) => {
  builder.addCase(changeUserMonthlyLimit.pending, (state) => {
    state.changeMonthlyLimitLoading = true;
  });
  builder.addCase(changeUserMonthlyLimit.fulfilled, (state, action) => {
    state.clientDetails.exchangerUserInfo.paymentLimit =
      action.payload.data?.exchangerUserInfo?.paymentLimit;
    state.changeMonthlyLimitLoading = false;
    closableNotification(t("alerts.userMonthlyLimitUpdated"), "success");
  });
  builder.addCase(changeUserMonthlyLimit.rejected, (state, action) => {
    errorsParser(action.payload);
    state.changeMonthlyLimitLoading = false;
  });
};

export {
  assignReferrerReducer,
  banClientsReducer,
  changeReferralLifeTimeValueReducer,
  changeUserMonthlyLimitReducer,
  changeUserReferralInfoReducer,
  changeWidgetStatusExchangerReducer,
  fetchClientsDetailsReducer,
  fetchClientsReducer,
  fetchReferralUsersByIdReducer,
  fetchWithdrawalRequisitionsReducer,
  getUserReferralInfoReducer,
  resetExchangerUserVerificationReducer,
};
