import { createAsyncThunk } from "@reduxjs/toolkit";

import { responseStatus } from "../../utils/consts";
import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const authenticationRequest = createAsyncThunk(
  "authSlice/authenticationRequest",
  async (authData, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api", authData.body, authData.headers);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const handleRegistration = createAsyncThunk(
  "authSlice/handleRegistration",
  async ({ data: registerData, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/users/registration/create-data",
        registerData
      );
      const userId = response.data[0]?.id;

      return {
        data: response.data,
        callback: callback(userId),
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response);
    }
  }
);

const handleCheckAuthCode = createAsyncThunk(
  "authSlice/handleCheckAuthCode",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api/users/registration/check-auth-code", data);

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const handleSetTwoFa = createAsyncThunk("authSlice/handleSetTwoFa", async (data, thunkAPI) => {
  try {
    const response = await axiosInstance.post("/api/users/registration/set-twofa", data);

    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const getAuthCodeExpiration = createAsyncThunk(
  "authSlice/handleGetAuthCodeExpiration",
  async ({ id, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(`/api/users/registration/${id}`);

      return response.data;
    } catch (error) {
      if (error.response.status === responseStatus.HTTP_NOT_FOUND) {
        return callback();
      }

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const recreateAuthCode = createAsyncThunk(
  "authSlice/recreateAuthCode",
  async ({ data, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api/users/registration/recreate-code", data);
      callback();

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const refreshToken = createAsyncThunk("authSlice/refreshToken", async (_, thunkAPI) => {
  try {
    const response = await axiosInstance("/api/refresh-token", userAuthenticationConfig());

    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const getEndpointPermissions = createAsyncThunk(
  "authSlice/getEndpointPermissions",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        "/api/endpoint-permissions/get-own-list",
        userAuthenticationConfig()
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const getAuthToken = createAsyncThunk("authSlice/getAuthToken", async ({ email }, thunkAPI) => {
  try {
    const response = await axiosInstance.post("/api/users/login", { email });
    return { ...response.data, email };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const recreateAuthCodeWithToken = createAsyncThunk(
  "authSlice/recreateAuthCodeWithToken",
  async ({ email, clientLoginToken }, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api/users/login/recreate-code", {
        email,
        clientLoginToken,
      });
      return { ...response.data, email };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const authenticationWithToken = createAsyncThunk(
  "authSlice/authenticationWithToken",
  async (authData, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api", authData);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export {
  authenticationRequest,
  authenticationWithToken,
  getAuthCodeExpiration,
  getAuthToken,
  getEndpointPermissions,
  handleCheckAuthCode,
  handleRegistration,
  handleSetTwoFa,
  recreateAuthCode,
  recreateAuthCodeWithToken,
  refreshToken,
};
