import styled, { css } from "styled-components";

const baseStyles = css`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ size }) => size && `font-size: ${size}px`};
  color: ${({ color, theme }) => color ?? theme.neutral.first};
  text-align: ${({ position }) => (position === "center" ? "center" : "left")};
`;

/**
 * @typedef {Object} StyledHeadingOneProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledHeadingOne component
 * @type {import("styled-components").StyledComponent<"h1", any, StyledHeadingOneProps, never>}
 */
export const StyledHeadingOne = styled.h1`
  font-size: 26px;
  font-weight: 500;
  line-height: 30px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledHeadingTwoProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledHeadingTwo component
 * @type {import("styled-components").StyledComponent<"h2", any, StyledHeadingTwoProps, never>}
 */
export const StyledHeadingTwo = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledHeadingThreeProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledHeadingThree component
 * @type {import("styled-components").StyledComponent<"h3", any, StyledHeadingThreeProps, never>}
 */
export const StyledHeadingThree = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledHeadingFourProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledHeadingFour component
 * @type {import("styled-components").StyledComponent<"h4", any, StyledHeadingFourProps, never>}
 */
export const StyledHeadingFour = styled.h4`
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledHeadingFiveProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledHeadingFive component
 * @type {import("styled-components").StyledComponent<"h5", any, StyledHeadingFiveProps, never>}
 */
export const StyledHeadingFive = styled.h5`
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledHeadingSixProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledHeadingSix component
 * @type {import("styled-components").StyledComponent<"h6", any, StyledHeadingSixProps, never>}
 */
export const StyledHeadingSix = styled.h6`
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledTextLargeProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledTextLarge component
 * @type {import("styled-components").StyledComponent<"p", any, StyledTextLargeProps, never>}
 */
export const StyledTextLarge = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledTextRegularProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledTextRegular component
 * @type {import("styled-components").StyledComponent<"p", any, StyledTextRegularProps, never>}
 */
export const StyledTextRegular = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  ${baseStyles};
`;

/**
 * @typedef {Object} StyledTextTinyProps
 * @property {number} [mt] - margin-top
 * @property {number} [mb] - margin-bottom
 * @property {number} [size] - font size
 * @property {string} [color] - text color
 * @property {string} [position] - text alignment ("left" or "center")
 */

/**
 * StyledTextTiny component
 * @type {import("styled-components").StyledComponent<"p", any, StyledTextTinyProps, never>}
 */
export const StyledTextTiny = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  ${baseStyles};
`;

const styleColor = (color, theme) => {
  switch (color) {
    // todo add more color case
    case "black":
      return `color: ${theme.neutral.black};`;
    case "primary":
      return `color: ${theme.primary.first};`;
    default:
      return `color: ${theme.neutral.third};`;
  }
};

export const StyledParagraph = styled.p`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  cursor: ${({ cursor }) => (cursor ? cursor : "unset")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  line-height: 17px;

  ${({ color, theme }) => styleColor(color, theme)}

  &:not(:last-child) {
    padding-bottom: 2px;
  }
`;

export const StyledSpan = styled.span`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  display: ${({ display }) => display || "inline"};
  cursor: ${({ cursor }) => (cursor ? cursor : "unset")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "14px")};
  font-style: normal;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};

  ${({ color, theme }) => styleColor(color, theme)}
`;
