import { createSlice } from "@reduxjs/toolkit";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { errorsParser } from "../../utils/errorsParser";
import { createExchanger, fetchExchangers } from "./asyncExchangersSlice";

const initialState = {
  exchangers: [],
  errors: null,
  loading: true,
  createExchangerState: {
    loading: false,
    errors: null,
  },
  changeExchangerStatusState: {
    loading: false,
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
};

const exchangersSlice = createSlice({
  name: "exchangersSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateExchangers: (state) => {
      state.exchangers = [];
      state.loading = true;
      state.errors = null;
    },
    removeCreateExchangerErrors: (state, action) => {
      const fieldName = action.payload;

      if (state.createExchangerState.errors) {
        // eslint-disable-next-line no-unused-vars
        const { [fieldName]: _, ...restErrors } = state.createExchangerState.errors;

        state.createExchangerState.errors = restErrors;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExchangers.fulfilled, (state, action) => {
      state.exchangers = action.payload.data;
      state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
      state.loading = false;
    });
    builder.addCase(fetchExchangers.rejected, (state, action) => {
      errorsParser(action.payload);
      state.loading = false;
    });
    builder.addCase(createExchanger.pending, (state) => {
      state.createExchangerState.loading = true;
    });
    builder.addCase(createExchanger.fulfilled, (state, action) => {
      state.createExchangerState.errors = null;
      state.createExchangerState.loading = false;
      state.exchangers = [action.payload.data];
      closableNotification(
        "The request to create an exchanger has been created, please wait for confirmation",
        "success"
      );
    });
    builder.addCase(createExchanger.rejected, (state, action) => {
      state.createExchangerState.errors = errorsParser(action.payload, true);
      state.createExchangerState.loading = false;
    });
  },
});

export const { setPaginationInfo, clearStateExchangers, removeCreateExchangerErrors } =
  exchangersSlice.actions;

export default exchangersSlice.reducer;
