import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { addToBlackListWallet, deleteWalletFromBlacklist, fetchBlackListWallet } from "./asyncBlackListWalletSlice";
import { errorsParser } from "../../utils/errorsParser";

const fetchBlackListWalletsReducer = (builder) => {
  builder.addCase(fetchBlackListWallet.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchBlackListWallet.fulfilled, (state, action) => {
    state.blackListWallets = action.payload.data;
    state.paginationInfo = { ...state.paginationInfo, ...action.payload.paginationInfo };
    state.loading = false;
  });
  builder.addCase(fetchBlackListWallet.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.loading = false;
  });
};

const addToBlackListWalletReducer = (builder) => {
  builder.addCase(addToBlackListWallet.pending, (state) => {
    state.addToBlackListWallets.loading = true;
  });
  builder.addCase(addToBlackListWallet.fulfilled, (state, action) => {
    state.blackListWallets = [action.payload.data, ...state.blackListWallets];
    state.addToBlackListWallets.loading = false;
  });
  builder.addCase(addToBlackListWallet.rejected, (state, action) => {
    errorsParser(action.payload);
    state.addToBlackListWallets.loading = false;
  });
};

const deleteWalletFromBlacklistReducer = (builder) => {
  builder.addCase(deleteWalletFromBlacklist.fulfilled, (state, action) => {
    state.blackListWallets = state.blackListWallets.filter((item) => item.id !== action.payload.id);
  });
  builder.addCase(deleteWalletFromBlacklist.rejected, (state, action) => {
    errorsParser(action.payload);
  });
};

export {
  fetchBlackListWalletsReducer,
  addToBlackListWalletReducer,
  deleteWalletFromBlacklistReducer
};
