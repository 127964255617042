import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { fetchLogs } from "./asyncLogsSlice";

const fetchLogsReducers = (builder) => {
  builder.addCase(fetchLogs.pending, (state) => {
    state.loading = true;
  });
  builder.addCase(fetchLogs.fulfilled, (state, action) => {
    state.logs = action.payload.data;
    state.loading = false;
  });
  builder.addCase(fetchLogs.rejected, (state, action) => {
    closableNotification(action.payload, "error");
    state.loading = false;
  });
};

export { fetchLogsReducers };