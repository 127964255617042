import Pusher from "pusher-js";

const PRIVATE_CHANNEL_OBJECT_NAME = "PrivateChannel";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const SITE_URL = window.location.origin;
const PUSHER_CHANNEL = BASE_URL && BASE_URL !== SITE_URL ? window.location.host : "main";

// DebugMode
Pusher.logToConsole = false;

let pusherOptions = {
  cluster: "eu",
  enabledTransports: ["ws"],
  useTLS: true
};

let pusherAuthHeaders = {
  auth: {
    authEndpoint: process.env.REACT_APP_SOCKET_AUTH_ENDPOINT,
    headers: {
      "Authorization": "Bearer "
    }
  }
};

export const PusherService = {
  pusher: null,

  /**
   * @returns {*}
   */
  getChannelObject () {
    return this.pusher?.channels?.channels[PUSHER_CHANNEL];
  },

  initNewAuthConnection () {
    let token = localStorage.getItem("token");

    if (token) {
      pusherAuthHeaders.auth.headers.Authorization = pusherAuthHeaders.auth.headers.Authorization + token;
      pusherOptions = { ...pusherOptions, ...pusherAuthHeaders };
    }

    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_PUBLIC_KEY, pusherOptions);
  },

  initNewConnection () {
    this.pusher = new Pusher(process.env.REACT_APP_PUSHER_PUBLIC_KEY, pusherOptions);
  },

  /**
   * @param eventName
   * @param callback
   */
  connectToSocket (eventName, callback) {
    if (!this.pusher) {
      this.initNewConnection();
    }

    if (!Object.keys(this.pusher.channels.channels).includes(PUSHER_CHANNEL)) {
      this.pusher.subscribe(PUSHER_CHANNEL);
    }

    this.pusher.channels.channels[PUSHER_CHANNEL].bind(eventName, callback);
  },

  unsubscribeFromPrivateChannels () {
    if (!this.pusher.channels.channels) {
      return;
    }

    Object.values(this.pusher.channels.channels).forEach(channel => {
      if (typeof channel === "object" && channel.constructor.name === PRIVATE_CHANNEL_OBJECT_NAME) {
        this.pusher.unsubscribe(channel.name);
      }
    });
  },

  unsubscribeFromAllChannels () {
    if (!this.pusher.channels.channels) {
      return;
    }

    Object.values(this.pusher.channels.channels).forEach(channel => {
      if (typeof channel === "object") {
        this.pusher.unsubscribe(channel.name);
      }
    });
  },

  /**
   * Only for private channels, such as: PusherService.triggerEvent("private-crash." + user.userId, "init", {"test": "asd"});
   * @param eventName
   * @param eventData
   */
  triggerEvent (eventName, eventData) {
    let channel = this.getChannelObject(PUSHER_CHANNEL);

    if (channel === undefined) {
      this.pusher.subscribe(PUSHER_CHANNEL);
      channel = this.getChannelObject(PUSHER_CHANNEL);
    }

    channel.bind("pusher:subscription_succeeded", () => {
      channel.trigger("client-" + eventName, eventData);
    });
  },

  unsubscribeFromChannel () {
    if (!this.pusher.channels.channels) {
      return;
    }

    this.pusher.unsubscribe(PUSHER_CHANNEL);
  },

  /**
   * @param channelNames
   */
  unsubscribeFromChannelArray (channelNames) {
    if (!this.pusher.channels.channels || !Array.isArray(channelNames)) {
      return;
    }

    channelNames.forEach(channelName => {
      this.pusher.unsubscribe(channelName);
    });
  },

  /**
   * @param arrayState
   * @param payload
   * @param searchableField
   */
  updateOrPushObjectInList (arrayState, payload, searchableField = "id") {
    let pusherObject = JSON.parse(payload);

    const index = arrayState.findIndex((obj) => obj[searchableField] === pusherObject[searchableField]);

    if (index >= 0) {
      arrayState[index] = pusherObject;
    } else {
      arrayState.unshift(pusherObject);
    }
  },

  /**
   * @param pusherObject
   * @param state
   * @param setState
   */
  updateSingleObject (pusherObject, state, setState) {
    if (!state || !setState) {
      return;
    }

    setState(pusherObject);
  }
};