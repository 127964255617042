import { createSlice } from "@reduxjs/toolkit";

import {
  changeAvailableServicesReducer,
  changeExchangerPairUnitActivityForAllReducer,
  changeExchangerPairUnitActivityReducer,
  changeExchangerPairUnitInputDataReducer,
  changeExchangerPairUnitMultiForAllReducer,
  changeExchangerPairUnitMultiReducer,
  changeExchangerPairUnitReducer,
  fetchAvailableServicesReducer,
  fetchExchangerPairUnitsReducer,
  fetchExchangerPairUnitsTabsReducer,
} from "./pairUnitReducers";
import { closableNotification } from "../../../components/elements/notification/ClosableNotification";
import { t } from "i18next";

const initialState = {
  pairUnits: [],
  error: null,
  loading: true,
  pairUnitsTab: {
    list: [],
    error: null,
    loading: true,
  },
  changeActivityState: {
    loading: false,
  },
  changeTabState: {
    loading: false,
  },
  changeDataState: {
    loading: false,
  },
  changeAvailableServices: {
    loading: false,
  },
  activeMultiple: [],
  availableServices: [],
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
  canEditFields: false,
  canEditConstant: false,
  refreshing: false,
};

const exchangerPairUnitSlice = createSlice({
  name: "exchangerPairUnitSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearExchangerPairUnitStore: (state) => {
      state.pairUnits = [];
      state.loading = true;
      state.error = null;
      state.availableServices = [];
      state.refreshing = false;
    },
    setAllMultipleId: (state) => {
      if (state.activeMultiple.length === state.pairUnits.length) {
        state.activeMultiple = [];
        return;
      }

      state.activeMultiple = state.pairUnits.map((item) => item.id);
    },
    setItemInMultipleArray: (state, action) => {
      if (!action.payload.isActive) {
        state.activeMultiple.push(action.payload.id);
      } else {
        state.activeMultiple = state.activeMultiple.filter((item) => item !== action.payload.id);
      }
    },
    updateEditPermissions: (state, action) => {
      state.canEditFields = action.payload.canEditFields;
      state.canEditConstant = action.payload.canEditConstant;
    },
    updateExchangerPairUnitsStore: (state, action) => {
      const { updatedData, callback } = action.payload;
      const [key, value] = Object.entries(updatedData)[0];

      const refreshedPairUnits = state.pairUnits.map(item => ({
        ...item,
        ...(key === "isActive" && { isActive: value }),
        fee: key !== "isActive" ? { ...item.fee, ...updatedData } : item.fee,
      }));

      state.pairUnits = refreshedPairUnits;
      state.refreshing = false;
      closableNotification(t("alerts.multipleEditMsg"), "success");
      callback();
    },
  },
  extraReducers: (builder) => {
    fetchExchangerPairUnitsReducer(builder);
    changeExchangerPairUnitActivityReducer(builder);
    fetchExchangerPairUnitsTabsReducer(builder);
    changeExchangerPairUnitReducer(builder);
    changeExchangerPairUnitInputDataReducer(builder);
    fetchAvailableServicesReducer(builder);
    changeAvailableServicesReducer(builder);
    changeExchangerPairUnitMultiReducer(builder);
    changeExchangerPairUnitActivityForAllReducer(builder);
    changeExchangerPairUnitMultiForAllReducer(builder);
  },
});

export const {
  setPaginationInfo,
  clearExchangerPairUnitStore,
  setAllMultipleId,
  setItemInMultipleArray,
  updateEditPermissions,
  updateExchangerPairUnitsStore,
} = exchangerPairUnitSlice.actions;

export default exchangerPairUnitSlice.reducer;
