import { memo } from "react";
import { Outlet } from "react-router-dom";

import SidebarContainer from "./sidebar/SidebarContainer";
import { StyledUserLayoutWrapper } from "./styledLayout";

const UserPageWrapper = ({ sidebarCollapsed }) => {
  return (
    <>
      <SidebarContainer />
      <StyledUserLayoutWrapper changeWidth={sidebarCollapsed}>
        <Outlet />
      </StyledUserLayoutWrapper>
    </>
  );
};

export default memo(UserPageWrapper);
