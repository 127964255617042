import styled from "styled-components";

const positionTemplate = {
  position: String,
};

const stylePosition = (position) => {
  switch (position) {
    case "center":
      return `
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
      `;
    default:
      return `
        
      `;
  }
};

export const StyledSpinner = styled("div", positionTemplate)`
  ${({ position }) => stylePosition(position)}
`;

export const StyledFragmentSpinner = styled("div", positionTemplate)`
  ${({ position }) => stylePosition(position)};
  border-radius: ${({ radius }) => (radius ? `${radius}px` : "15px")};
  background-color: ${({ background }) =>
    !background ? "rgba(215, 215, 230, 0.5);" : "transparent"};
`;

export const StyledLoadButton = styled.div`
  ${({ width }) => width && `max-width: ${width}px; width: 100%`};
  ${({ fullWidth }) => fullWidth && "width: 100%"};
  display: inline-grid;

  .loading-button {
    text-align: left !important;
    position: relative;
    opacity: 0.5;

    &__spinner {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const StyledPageSpinner = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ pt }) => pt && `padding-top: ${pt}px`};
`;
