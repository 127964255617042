import { useState } from "react";

import { useAuthenticationGuard } from "../../utils/customHooks/useAuthenticationGuard";
import { usePermissionGuard } from "../../utils/customHooks/usePermissionGuard";
import Header from "./header/Header";
import PageWrapper from "./PageWrapper";
import { StyledLayoutWrapper } from "./styledLayout";
import UserPageWrapper from "./UserPageWrapper";
import { useSelector } from "react-redux";

const Layout = ({ authenticated, isAccountPathname }) => {
  const { sidebarCollapsed, sidebarToggled } = useSelector((store) => store.theme);

  useAuthenticationGuard();

  // useRouterGuard(authenticated, routers);

  usePermissionGuard();

  return (
    <>
      <Header
        authenticated={authenticated}
        isAccountPathname={isAccountPathname}
      />
      <StyledLayoutWrapper changeWidth={sidebarToggled}>
        {isAccountPathname ? (
          <UserPageWrapper sidebarCollapsed={sidebarCollapsed} />
        ) : (
          <PageWrapper />
        )}
      </StyledLayoutWrapper>
    </>
  );
};

export default Layout;
