import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchPairs = createAsyncThunk("pairsSlice/fetchPairs", async (filterUrl, thunkAPI) => {
  try {
    const response = await axiosInstance(`/api/pairs${filterUrl}`, userAuthenticationConfig());

    return {
      data: response.data["hydra:member"],
      paginationInfo: {
        totalPageCount: response.data["hydra:totalPageCount"],
        totalItems: response.data["hydra:totalItems"],
      },
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data);
  }
});

const changePairPercent = createAsyncThunk(
  "pairsSlice/changePairPercent",
  async ({ id, newPercent }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/pairs/${id}/change-percent`,
        { percent: newPercent },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changePairActivity = createAsyncThunk(
  "pairsSlice/changePairActivity",
  async ({ id, isActive }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/pairs/${id}`,
        { isActive: !isActive },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changePairMulti = createAsyncThunk(
  "pairsSlice/changePairMulti",
  async ({ pairs, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/pairs/update",
        { ...data, pairs },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changePairMultiForAll = createAsyncThunk(
  "pairsSlice/changePairMultiForAll",
  async ({ applyToAll, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/pairs/update",
        { ...data, applyToAll },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  changePairActivity,
  changePairMulti,
  changePairPercent,
  fetchPairs,
  changePairMultiForAll,
};
