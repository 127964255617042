import { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { authenticationRequest, getEndpointPermissions } from "../../../store/auth/asyncAuthSlice";
import { authentication } from "../../../utils/authenticationRequest";
import { helmetHtmlByLang } from "../../../utils/consts";
import { StyledContainer } from "../../styles/styledContainer";
import ExchangerEmailStep from "./steps/ExchangerEmailStep";
import ExchangerTwoFaFormStep from "./steps/ExchangerTwoFaFormStep";
import ExchangerVerificationCodeStep from "./steps/ExchangerVerificationCodeStep";
import { StyledAuthFormWrapper, StyledAuthWrapper } from "../styledAuth";

const ExchangerAuthContainer = ({ t }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authState = useSelector((store) => store.auth);
  const siteConfigState = useSelector((state) => state.theme);

  // for Google auth
  const [authData, setAuthData] = useState({
    headers: null,
    body: null,
  });

  const { authenticated, loading, userTwoFa, exchangerAuth } = authState;
  const { clientLoginToken } = exchangerAuth || {};
  const { siteConfig } = siteConfigState;

  const authStep = useMemo(() => {
    if (!clientLoginToken && !userTwoFa) {
      return <ExchangerEmailStep t={t} setAuthData={setAuthData} authData={authData} />;
    }
    if (clientLoginToken && !userTwoFa) {
      return <ExchangerVerificationCodeStep t={t} />;
    }
    if (userTwoFa) {
      return (
        <ExchangerTwoFaFormStep
          loading={loading}
          authData={authData}
          setAuthData={setAuthData}
          t={t}
        />
      );
    }
  }, [userTwoFa, authData, setAuthData, t, exchangerAuth, clientLoginToken]);

  // for Google auth
  useEffect(() => {
    if (authData.headers) {
      dispatch(authenticationRequest(authData));
    }
  }, [authData]);

  useEffect(() => {
    if (authenticated) {
      dispatch(getEndpointPermissions()).then(() => {
        authentication(navigate, authenticated, dispatch);
      });
    }
  }, [authenticated]);

  return (
    <StyledContainer>
      <Helmet>
        <title>{t("meta.title", { title: siteConfig.metaTitle })}</title>
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/login"}
          hrefLang={helmetHtmlByLang.en}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ua/login"}
          hrefLang={helmetHtmlByLang.ua}
        />
        <link
          rel="alternate"
          href={"https://" + window.location.hostname + "/ru/login"}
          hrefLang={helmetHtmlByLang.ru}
        />
      </Helmet>
      <StyledAuthWrapper>
        <StyledAuthFormWrapper>
          {authStep}
        </StyledAuthFormWrapper>
      </StyledAuthWrapper>
    </StyledContainer>
  );
};

export default withTranslation("translation", { keyPrefix: "auth.exchangerAuth" })(
  ExchangerAuthContainer
);
