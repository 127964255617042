import styled from "styled-components";

import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";

export const StyledSidebar = styled.div`
  height: calc(100% - 70px);
  padding: 32px 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2px;
  transition: background-color 0.3s ease-in-out;

  .sidebar-collapsed {
    cursor: initial;

    &:hover {
      .sidebar-collapsed__btn {
        color: ${({ theme }) => theme.sidebar.iconColor};
      }
    }

    &__btn:hover {
      color: #FFF !important;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 992px) {
    height: 100%;
    padding: 16px 0;
    background-color: ${({ theme }) => theme.sidebar.backgroundColor};

    .sidebar-collapsed {
      display: none;
    }

    .balance {
      margin: 0 20px 20px;
      color: #FFFFFF;
      display: flex;
      gap: 6px;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
      }
      
      &::before {
        display: none;
      }
    }
  }
`;

// export const StyledSeparator = styled.div`
//   margin: 5px 15px 5px 15px;
//   height: 1px;
//   background-color: ${({ theme }) => theme.neutral.fourth};
//   z-index: 10;
// `;

export const StyledSidebarItem = styled.div`
  width: 100%;
  margin-right: 20px;
  padding: 17px 12px 17px 20px;
  display: flex;
  align-items: center;
  gap: 12px;
  border: none;
  border-radius: 0 17px 17px 0;
  outline: none;
  
  ${StyledTextRegular} {
    color: ${({ theme }) => theme.sidebar.textColor};
    white-space: nowrap;
  }

  ${({ collapsed }) => collapsed && `
    margin-right: 0;
    
    ${StyledTextRegular} {
      display: none;
    }
  `}
  
  ${StyledSpan} {
    width: 20px;
    height: 20px;
    color:  ${({ theme }) => theme.sidebar.iconColor};
  }

  @media screen and (max-width: 992px) {
    padding: 14px 12px 14px 20px;
  }

  @media screen and (max-width: 768px) {
    ${StyledTextRegular} {
      display: block;
    }
  }
`;

export const StyledSidebarSiteLinks = styled.div`
  .active,
  .active_sub-link {
    ${StyledSpan},
    ${StyledTextRegular} {
      color: ${({ theme }) => theme.sidebar.currentItemTextColor};
    }
  }

  .active {
    position: relative;

    &::before {
      content: "";
      width: 5px;
      height: 100%;
      background-color: ${({ theme }) => theme.brandColor};
      position: absolute;
      top: 0;
      left: 0;
    }

    ${StyledSidebarItem} {
      background-color: ${({ theme }) => theme.sidebar.currentItemBackgroundColor};
    }

    @media screen and (max-width: 992px) {
      &::before {
        width: 3px;
      }
    }
  }
`;

export const StyledSidebarLink = styled.div`
  width: 100%;
  display: inline-flex;
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;

  &:hover {
    ${StyledSpan}, ${StyledTextRegular} {
      color: ${({ theme }) => theme.sidebar.currentItemTextColor};
    }
  }
`;

export const StyledSidebarSubLinkList = styled.div`
  // height: ${({ isOpen, bodyHeight }) => (isOpen ? `${bodyHeight}px` : "0")};
  // display: flex;
  // flex-direction: column;
  // gap: 4px;
  // overflow: hidden;
  // transition: ${({ animateOpen }) => animateOpen && "all 0.3s ease"};
  //
  // @media screen and (max-width: 768px) {
  //   height: ${({ isOpen, bodyHeight }) => (isOpen ? `${bodyHeight}px` : "0")};
  // }
`;

export const StyledSidebarSubLinkItem = styled.div`
  // padding: 10px 0 10px 15px;
  // display: flex;
  // align-items: center;
  // gap: 28px;
  // cursor: pointer;
  // border-radius: 12px;
  // ${({ active, theme }) => active && `background-color: ${theme.primary.third};`}
  //
  // ${StyledTextRegular} {
  //   color: ${({ theme, active }) => (active ? theme.primary.first : theme.neutral.first)};
  // }
  //
  // &:before {
  //   content: "●";
  //   display: block;
  //   border-radius: 50%;
  //   color: ${({ theme, active }) => (active ? theme.primary.first : theme.neutral.fourth)};
  // }
  //
  // &:hover {
  //   ${StyledTextRegular} {
  //     color: ${({ theme }) => theme.primary.first};
  //   }
  //
  //   &:before {
  //     color: ${({ theme }) => theme.primary.first};
  //   }
  // }
`;
