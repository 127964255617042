import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AuthCode from "react-auth-code-input";
import { Trans } from "react-i18next";

import {
  authenticationWithToken,
  recreateAuthCodeWithToken,
} from "../../../../store/auth/asyncAuthSlice";
import { clearExchangerAuth } from "../../../../store/auth/authSlice";
import { maxLengthSymbols } from "../../../../utils/consts";
import { formatTimeLeft, startReverseCountdown } from "../../../../utils/customFunc/dateUtils";
import LoadButton from "../../../elements/spinner/ButtonSpinner";
import { StyledButton } from "../../../styles/styledButton";
import { StyledMainTitle } from "../../../styles/styledTitle";
import { StyledParagraph } from "../../../styles/styledTypography";
import { StyledExchangerAuthNewCode } from "../styledExchnagerAuth";
import { StyledLabel } from "../../../elements/inputGroup/styledInputGroup";
import { StyledAuthActions, StyledAuthBackButton } from "../../styledAuth";

const ExchangerVerificationCodeStep = ({ t }) => {
  const dispatch = useDispatch();

  const { exchangerAuth, loading } = useSelector((state) => state.auth) || {};

  const { t: tAuth } = useTranslation("translation", { keyPrefix: "auth" });

  const [code, setCode] = useState("");
  const [remainingTime, setRemainingTime] = useState("");
  const [error, setError] = useState(null);

  const { clientLoginToken, email, datetime, attempts } = exchangerAuth || {};

  const handleSave = () => {
    if (!attempts) {
      return;
    }

    dispatch(authenticationWithToken({ code, clientLoginToken, authenticationEmail: email }));
  };

  const handleChange = (code) => {
    setCode(code);
  };

  const handleGoBack = () => {
    dispatch(clearExchangerAuth());
  };

  const handleResendCode = () => {
    dispatch(
      recreateAuthCodeWithToken({
        email,
        clientLoginToken,
      })
    );

    setError(null);
    setCode("");
  };

  useEffect(() => {
    const dateCreationTimestamp = datetime * 1000;
    const remainingTimeInSeconds = 60;

    const stopCountdown = startReverseCountdown(
      dateCreationTimestamp,
      remainingTimeInSeconds,
      (timeLeft) => {
        const { seconds } = formatTimeLeft(timeLeft);
        setRemainingTime(seconds);
      },
      () => {
        setRemainingTime(null);
      }
    );

    return () => {
      if (stopCountdown) {
        stopCountdown();
      }
    };
  }, [datetime]);

  useEffect(() => {
    if (code.length === maxLengthSymbols.TWO_FA_CODE) {
      handleSave();
    }
  }, [code]);

  useEffect(() => {
    if (!attempts) {
      setError(t("emailStep.errors.emptyAttempts"));
    }
  }, [attempts]);

  return (
    <>
      <StyledMainTitle mb="34" size="36">
        {t("emailStep.title")}
      </StyledMainTitle>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <StyledLabel htmlFor="twofa">{t("codeStep.label")}</StyledLabel>
        <AuthCode
          id="twofa"
          length={maxLengthSymbols.TWO_FA_CODE}
          containerClassName="twofa-container"
          inputClassName="twofa-container__input"
          allowedCharacters="numeric"
          onChange={handleChange}
          name="code"
        />
        <StyledExchangerAuthNewCode>
          {remainingTime ? (
            <StyledParagraph>
              <Trans
                components={{ b: <b /> }}
              >
                {t("codeStep.newCodeSent", { value: remainingTime })}
              </Trans>
            </StyledParagraph>
          ) : (
            <button
              type="button"
              className="send-button"
              onClick={handleResendCode}
              disabled={loading}
            >
              {t("codeStep.sendNewCode")}
            </button>
          )}
        </StyledExchangerAuthNewCode>
        <StyledAuthActions>
          {loading ? (
            <LoadButton color="main" width="100%" text={t("codeStep.submit")} />
          ) : (
            <StyledButton
              disabled={loading || Number(code.length) < 6 || !attempts}
              color="main"
              width="100%"
              type="submit"
            >
              {t("codeStep.submit")}
            </StyledButton>
          )}
        </StyledAuthActions>
        <StyledAuthBackButton>
          <button disabled={loading} type="button" onClick={handleGoBack}>
            {t("codeStep.back")}
          </button>
        </StyledAuthBackButton>
      </form>
    </>
  );
};

export default ExchangerVerificationCodeStep;
