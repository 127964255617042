import styled from "styled-components";

import { StyledSelect } from "../../styles/styledSelect";

export const StyledLanguageSelect = styled(StyledSelect)`
  width: 40px;
  //margin-right: 16px;
  input {
    display: none;
  }

  .lang-select {
    padding: 0;
    background-color: transparent;
    box-shadow: none;
    z-index: 1;
    line-height: inherit;
    transition: inherit;
    border: none;

    &:hover {
      color: ${({ theme }) => theme.neutral.white};
    }

    .lang-select__item {
      width: 100%;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
    }

    &__text {
      display: none;
    }

    &__pref {
      color: #ffffff;
    }

    .rc-select-selector {
      min-height: auto;
      padding: 0;
    }

    .rc-select-selection-item {
      padding: 0;
      text-transform: uppercase;
    }

    .rc-select-arrow {
      width: 16px;
      height: 16px;
      top: 0;

      .rc-select-arrow-icon {
        width: 15px;
        height: 15px;
        position: absolute;
        top: 0;
        left: 0;
        &:before {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .rc-select-dropdown {
    padding: 6px;
    
    .rc-select-item-option {
      color: ${({ theme }) => theme.text};
      padding: 0;

      .lang-select__item {
        padding: 10px 15px;
      }

      .lang-select__text {
        display: block;
      }

      .lang-select__pref {
        display: none;
      }

      &:hover {
        color: ${({ theme }) => theme.primary.first};
        .option-select-item {
          background-color: transparent;
        }
        background-color: transparent;

        .lang-select__text {
          color: ${({ theme }) => theme.neutral.first};
        }
      }
    }

    .rc-select-item-option.active {
      border-radius: 8px;
      background-color: ${({ theme }) => theme.primary.third};
      .lang-select__text {
        color: ${({ theme }) => theme.primary.first};
      }
    }

    .rc-select-item-option-selected {
      color: #ffffff;
    }
  }
`;
