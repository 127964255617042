import { getItemsPerPage } from "../../../utils/customFunc/getItemsPerPage";
import { fetchFilterData } from "../../../utils/fetchFilterData";

const transformFilterString = (str) => {
  const replacements = {
    "paymentSystem.name": "paymentSystem",
    "service.title": "service",
    "paymentPairUnit.currency": "currency",
  };

  return str.replace(
    /paymentSystem\.name|service\.title|paymentPairUnit\.currency/g,
    (match) => replacements[match]
  );
};

const getFiltersUrlPerRole = (filterData, paginationInfo, isAdminFilters) => {
  const { currency, ...restFilters } = filterData || {};

  const mainFilters = { "currency.asset": currency };

  const exchangerFilters = {
    "paymentPairUnit.currency": currency,
  };

  const filterUrl = fetchFilterData({
    ...restFilters,
    ...(isAdminFilters ? mainFilters : exchangerFilters),
    ...getItemsPerPage(paginationInfo),
  });

  return filterUrl;
};

const getSelectedPairUnits = (pairUnits, selectedRows) => {
  const mainSet = new Set(selectedRows);

  return (
    pairUnits
      ?.map(({ id, ...rest }) => {
        if (mainSet.has(id)) {
          return {
            id,
            ...rest,
          };
        }
      })
      .filter((item) => !!item?.exchangerPairUnitId) || []
  );
};

export { getFiltersUrlPerRole, getSelectedPairUnits, transformFilterString };
