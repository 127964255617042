import styled from "styled-components";

import { StyledAuthFormWrapper } from "../styledAuth";

export const StyledLoginWrapper = styled(StyledAuthFormWrapper)`
  .forgot-password {
    padding-bottom: 24px;
    text-align: right;

    a {
      color: ${({ theme }) => theme.primary.first};
      font-weight: 500;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;