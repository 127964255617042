import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";

const fetchExchangerPairUnits = createAsyncThunk(
  "exchangerPairUnitSlice/fetchExchangerPairUnits",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/pair-units${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const changeExchangerPairUnitActivity = createAsyncThunk(
  "exchangerPairUnitSlice/changeExchangerPairUnitActivity",
  async ({ data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/pair-units/fill",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeExchangerPairUnitActivityForAll = createAsyncThunk(
  "exchangerPairUnitSlice/changeExchangerPairUnitActivityForAll",
  async ({ isActive, applyToAll }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/pair-units/fill",
        { isActive, applyToAll },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchExchangerPairUnitsTab = createAsyncThunk(
  "exchangerPairUnitSlice/fetchExchangerPairUnitsTab",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance("/api/pair-unit-tabs", userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeExchangerPairUnit = createAsyncThunk(
  "exchangerPairUnitSlice/changeExchangerPairUnit",
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/pair-units/${id}`,
        { ...data },
        userAuthenticationConfig(false)
      );

      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeExchangerPairUnitMulti = createAsyncThunk(
  "pairUnitSlice/changeExchangerPairUnitMulti",
  async ({ pairUnits, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/pair-units/update",
        { ...data, pairUnits: pairUnits },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeExchangerPairUnitMultiForAll = createAsyncThunk(
  "pairUnitSlice/changeExchangerPairUnitMultiForAll",
  async ({ applyToAll, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/pair-units/update",
        { ...data, applyToAll },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeExchangerPairUnitInputData = createAsyncThunk(
  "paymentSystemSlice/changeExchangerPairUnitInputData",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/exchanger-fees/${id}`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const fetchAvailableServices = createAsyncThunk(
  "exchangerPairUnitSlice/fetchAvailableServices",
  async (_, thunkAPI) => {
    try {
      const response = await axiosInstance(
        "/api/exchanger/rate-services",
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeAvailableServices = createAsyncThunk(
  "exchangerPairUnitSlice/changeAvailableServices",
  async ({ id, baseCurrencyRateServiceName }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/pair-units/${id}/change-rate-service`,
        { baseCurrencyRateServiceName },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  changeAvailableServices,
  changeExchangerPairUnit,
  changeExchangerPairUnitActivity,
  changeExchangerPairUnitInputData,
  changeExchangerPairUnitMulti,
  fetchAvailableServices,
  fetchExchangerPairUnits,
  fetchExchangerPairUnitsTab,
  changeExchangerPairUnitActivityForAll,
  changeExchangerPairUnitMultiForAll,
};
