import { StyledBreakWord } from "../../styles/StyledBreakWord";
import {
  StyledChildrenContainer,
  StyledPageHeadWrapper,
  StyledPagePrevious,
  StyledPageTitle,
  StyledPageTitleContainer,
} from "./styledPageHead";

export const PageHead = ({ title, previous, previousLink, children, pb, actionsInside, justifyContent = "space-between", className = "" }) => {
  return (
    <StyledPageHeadWrapper pb={pb} className={className} justifyContent={justifyContent}>
      <StyledPageTitleContainer actionsInside={actionsInside}>
        {previous && <StyledPagePrevious to={previousLink}>{previous}</StyledPagePrevious>}
        {title && (
          <StyledPageTitle previous={previous}>
            <StyledBreakWord>{title}</StyledBreakWord>
          </StyledPageTitle>
        )}
      </StyledPageTitleContainer>
      {children && <StyledChildrenContainer>{children}</StyledChildrenContainer>}
    </StyledPageHeadWrapper>
  );
};

export default PageHead;
