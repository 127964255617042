import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { authenticationWithToken } from "../../../../store/auth/asyncAuthSlice";
import TwoFa from "../../../elements/twoFa/TwoFa";
import { StyledMainTitle } from "../../../styles/styledTitle";
import { unsetAuthData } from "../../../../store/auth/authSlice";
import { StyledAuthBackButton } from "../../styledAuth";

const ExchangerTwoFaFormStep = ({ setAuthData, authData }) => {
  const dispatch = useDispatch();
  const { t: tAuth } = useTranslation("translation", { keyPrefix: "auth" });
  const { firstLoginData, loading, exchangerAuth, userTwoFa } =
  useSelector((state) => state.auth) || {};

  const [twoFa, setTwoFa] = useState("");

  const { clientLoginToken, email } = exchangerAuth || {};
  const tempQRCode = firstLoginData?.tempQRCode;
  const tempSecret = firstLoginData?.tempSecret;

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (authData.headers) {
      setAuthData((prev) => ({
        ...prev,
        body: {
          twofa: twoFa,
        },
      }));
    } else {
      dispatch(
        authenticationWithToken({ twofa: twoFa, clientLoginToken, authenticationEmail: email })
      );
    }
  };

  const handleChange = (twoFa) => {
    setTwoFa(twoFa);
  };

  const handleGoBack = () => {
    dispatch(unsetAuthData());
  };

  return (
    <>
      <StyledMainTitle mb="34" size="36">
        {tAuth("twoFa.title")}
      </StyledMainTitle>
      <TwoFa
        handleSubmit={handleSubmit}
        loading={loading}
        t={tAuth}
        tempQRCode={tempQRCode}
        tempSecret={tempSecret}
        handleChange={handleChange}
        value={twoFa}
        setValue={setTwoFa}
      />
      <StyledAuthBackButton>
        <button disabled={loading} type="button" onClick={handleGoBack}>
          {tAuth("exchangerAuth.codeStep.back")}
        </button>
      </StyledAuthBackButton>
    </>
  );
};

export default ExchangerTwoFaFormStep;
