import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const getReportsInfo = createAsyncThunk("reports/getReportsInfo", async (filterUrl, thunkAPI) => {
  try {
    const response = await axiosInstance(
      `/api/orders-report${filterUrl}`,
      userAuthenticationConfig()
    );

    return {
      data: response.data,
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data?.data);
  }
});

const fetchTrafficLinks = createAsyncThunk(
  "reports/fetchTrafficLinks",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/traffic-links${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const createTrafficLink = createAsyncThunk(
  "reports/createTrafficLink",
  async ({ callback, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/traffic-links",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const updateTrafficLink = createAsyncThunk(
  "reports/updateTrafficLink",
  async ({ token }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger/update-traffic-transitions",
        { token },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const fetchTrafficLinkById = createAsyncThunk(
  "reports/fetchTrafficLinkById",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axiosInstance(`/api/traffic-links/${id}`, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const deleteTrafficLinkById = createAsyncThunk(
  "reports/deleteTrafficLinkById",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(
        `/api/exchanger-traffic-links/${id}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        id,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const fetchTrafficUsers = createAsyncThunk(
  "reports/fetchTrafficUsers",
  async ({ filterUrl }, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-user-infos${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

export {
  createTrafficLink,
  deleteTrafficLinkById,
  fetchTrafficLinkById,
  fetchTrafficLinks,
  fetchTrafficUsers,
  getReportsInfo,
  updateTrafficLink,
};
