import { permissionAdminEndpoints } from "./exchanger/admin";
import { permissionManagerEndpoints } from "./exchanger/manager";
import { permissionSupportEndpoints } from "./exchanger/support";
import { permissionUserEndpoints } from "./exchanger/user";
import { permissionMainAdminEndpoints } from "./main/admin";
import { permissionMainMerchantEndpoints } from "./main/merchant";
import { permissionMainSystemAdminEndpoints } from "./main/systemAdmin";

const mergePermissionEndpoints = (...endpointGroups) => {
  return endpointGroups.reduce((mergedEndpoints, currentEndpoints) => {
    Object.entries(currentEndpoints).forEach(([key, value]) => {
      if (
        !mergedEndpoints[key] ||
        (mergedEndpoints[key].hasFullAccess === false && value.hasFullAccess === true) ||
        (mergedEndpoints[key].isTwoFaRequired === false && value.isTwoFaRequired === true)
      ) {
        mergedEndpoints[key] = value;
      }
    });

    return mergedEndpoints;
  }, {});
};

export const groupPermissionEndpoints = mergePermissionEndpoints(
  permissionAdminEndpoints,
  permissionManagerEndpoints,
  permissionSupportEndpoints,
  permissionUserEndpoints,
  permissionMainAdminEndpoints,
  permissionMainMerchantEndpoints,
  permissionMainSystemAdminEndpoints
);
