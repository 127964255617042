import i18n from "./i18n";
import { defaultLanguage } from "./utils/consts";

let currentLanguage = i18n.language || defaultLanguage;

class i18nHelper {
  static setCurrentLanguage(language) {
    currentLanguage = language;
    i18n.changeLanguage(language);
  }
  static getCurrentLanguage() {
    return currentLanguage;
  }
}

export default i18nHelper;
