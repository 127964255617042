export default {
  switchPosition: "1px",
  primary: {
    first: "#4D6EDD",
    second: "#6282EF",
    third: "#F1F4FE",
    fourth: "rgb(77, 110, 221, 0.05)",
    fifth: "#F6FAFE",
  },
  neutral: {
    first: "#0D1421",
    second: "#26282F",
    third: "#535A64",
    fourth: "#707E93",
    five: "#E1E5F1",
    six: "#495071",
    // 6E6E6E - 50%
    seven: "rgba(110, 110, 110, 0.5)",
    eight: "rgba(149, 149, 149, 0.15)",
    tenth: "#959595",
    eleventh: "#F0F4F9",
    twelfth: "#D5E5F0",
    black: "#000000",
    white: "#FFFFFF",
  },
  info: {
    first: "#335CEA",
    second: "#EFEFFF",
    third: "#ECF3FB",
  },
  warning: {
    first: "#E89E00",
    second: "#FDF8ED",
    third: "#FCF7E5",
  },
  success: {
    first: "#0CAF2A",
    second: "#ECF9EE",
    third: "#E6F4F0",
  },
  danger: {
    first: "#E11C3D",
    second: "#FFF2F2",
    third: "#FBE8E9",
  },
  sidebar: {
    textColor: "#E1E5F1",
    iconColor: "#9096B1",
    backgroundColor: "#2D3038",
    currentItemTextColor: "#FFFFFF",
    currentItemBackgroundColor: "#3A3F4B",
  },
  customSelect: {
    dropdown: {
      borderColor: "#D5E5F0",
      backgroundColor: "#FFFFFF",
      textColor: "#59618A",
      selectedBackgroundColor: "#ECF3FB",
      itemBackgroundColorHover: "#F6FAFE",
    },
  },
  switch: {
    backgroundColor: "#9096B1",
    borderColor: "#9096B1",
  },
  datepicker: {
    borderColor: "#D5E5F0",
    backgroundColor: "#FFFFFF",
    textColor: "#0D1421",
    iconColor: "#707E93",
  },
  input: {
    borderColor: "#D5E5F0",
    backgroundColor: "#FFFFFF",
    textColor: "#0D1421",
    placeholderColor: "#707E93",
    readonlyColor: "#565c63",
    inputIconColor: "#B2B8C4",
  },
  modal: {
    borderColor: "#D5E5F0",
    backgroundColor: "#FFFFFF",
    textColor: "#59618A",
  },
  card: {
    backgroundColor: "#F0F4F9",
    iconColor: "#9096B1",
    reportLabel: "#535A64",
  },
  table: {
    evenTableRowColor: "#F8FBFF",
    borderColor: "#D5E5F0",
    backgroundColorMobileHeader: "#F6FAFE",
    checkedElementColor: "#ECF3FB",
    thead: {
      backgroundColor: "#F0F4F9",
      textColor: "#707E93",
    },
    tbody: {
      borderColor: "#D5E5F0",
      evenBackgroundColor: "#F9F9FF",
      hoverBackgroundColor: "#ECF3FB",
      scrollTrackColor: "#F0F4F9",
      scrollThumbColor: "#C5CCD3",
    },
    card: {
      headBackgroundColor: "#F6FAFE",
    },
  },
  filter: {
    iconColor: "#9096B1",
    backgroundColor: "#FFFFFF",
    backgroundColorHover: "#F6FAFE",
    borderColor: "#D5E5F0",
  },
  tabs: {
    borderColor: "#D5E5F0",
    activeTextColor: "#4D6EDD",
    notActiveTextColor: "#535A64",
    iconActiveColor: "#335CEA",
    iconNotActiveColor: "#9096B1",
  },
  alert: {
    success: {
      borderColor: "#2FB499",
      backgroundColor: "#F8FFFF",
      textColor: "#16A172",
      iconColor: "#02BC7D",
    },
    info: {
      borderColor: "#4F81FE",
      backgroundColor: "#F8F8FF",
      textColor: "#557BDB",
      iconColor: "#4F81FE",
    },
  },
  mainTemplateBackgroundColor: "#FFFFFF",
  headerBackgroundColor: "#2D3038",
  bodyBackgroundColor: "#FFFFFF",
  brandColor: "#4D6EDD",
};
