import { createSlice } from "@reduxjs/toolkit";
import { fetchLogsReducers } from "./logsReducers";

const initialState = {
  logs: [],
  loading: true,
};

const logsSlice = createSlice({
  name: "logsSlice",
  initialState,
  reducers: {
    clearLogsStore: (state) => {
      state.logs = [];
      state.loading = true;
    },
  },
  extraReducers: (builder) => {
    fetchLogsReducers(builder);
  },
});

export const { clearLogsStore } = logsSlice.actions;

export default logsSlice.reducer;