import Select, { Option } from "rc-select";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import i18nHelper from "../../../i18nHelper";
import { supportedLanguages } from "../../../utils/consts";
import { StyledLanguageSelect } from "./styledLanguageSelect";

const regex = new RegExp(`^/(${Object.keys(supportedLanguages).join("|")})/`);

const LanguageSelect = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { siteConfig } = useSelector((store) => store.theme);

  const isMain = process.env.REACT_APP_BASE_URL === window.location.origin;

  const languages = isMain
    ? Object.entries(supportedLanguages)
    : Object.entries(supportedLanguages).filter(([key]) => siteConfig.siteLanguages?.includes(key));

  const replacePathName = pathname.replace(regex, "/");

  const handleChangeLang = (value) => {
    i18nHelper.setCurrentLanguage(value);

    // TODO: Previous multilanguage realization
    // const newPath = `/${value}${replacePathName}`;
    //
    // if (window.location.search.length > 0) {
    //   navigate(newPath + window.location.search, { replace: true });
    // } else {
    //   navigate(newPath, { replace: true });
    // }
  };

  // TODO: Check if it is needed
  // useEffect(() => {
  //   if (!!locale && !Object.keys(supportedLanguages).some((lang) => lang === locale)) {
  //     const newPath = pathname.replace(`/${locale}/`, "/");
  //     navigate(newPath);
  //
  //     dispatch(setLanguage(defaultLanguage));
  //   }
  // }, [locale]);

  return (
    <StyledLanguageSelect id="lang" className="lang-select-wrapper">
      <Select
        className="custom-select lang-select"
        name="lang"
        value={i18nHelper.getCurrentLanguage()}
        onChange={(value) => handleChangeLang(value)}
        getPopupContainer={() => document.getElementById("lang")}
        dropdownMatchSelectWidth={false}
        direction="rtl"
      >
        {languages.map(([key, value]) => {
          return (
            <Option
              value={key}
              key={key}
              className={key === i18nHelper.getCurrentLanguage() ? "active" : ""}
            >
              <div className="option-select-item lang-select__item">
                <div className="lang-select__pref">{key.toUpperCase()}</div>
                <div className="lang-select__text">{value.description}</div>
              </div>
            </Option>
          );
        })}
      </Select>
    </StyledLanguageSelect>
  );
};

export default LanguageSelect;
