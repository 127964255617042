import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { Trans, withTranslation } from "react-i18next";

import i18nHelper from "../../../i18nHelper";
import withPermissionCheckComponent from "../../../utils/customHOC/withPermissionCheckComponent";
import Can from "../../elements/can/Can";
import { StyledSpan, StyledTextRegular } from "../../styles/styledTypography";
import SidebarItemSubLink from "./SidebarItemSubLink";
import { StyledSidebarLink, StyledSidebarSubLinkList, StyledSidebarItem } from "./styledSidebar";
import { handleSidebarToggled } from "../../../store/theme/themeSlice";

const heightSubItem = 40;

const SidebarItem = ({
  animateOpen,
  setAnimateOpen,
  as,
  to,
  icon,
  title,
  subLink,
  subRelation,
  t,
  permissions,
  perform,
}) => {
  const dispatch = useDispatch();

  const userState = useSelector((store) => store.user);
  const { sidebarCollapsed } = useSelector((store) => store.theme);

  const { user } = userState;

  const language = i18nHelper.getCurrentLanguage();

  const match = useMatch({
    path: subRelation ? `${language}/crm/${subRelation}/*` : `${language}${to}`,
  });

  const sidebarItemFactory = (subLink) => {
    if (perform) {
      return (
        <Can
          role={user?.roles}
          perform={perform}
          yes={() => (
            <StyledSidebarLink
              as={as}
              to={to}
              className={match && "active"}
              onClick={() => dispatch(handleSidebarToggled())}
            >
              <StyledSidebarItem collapsed={sidebarCollapsed}>
                <StyledSpan fontSize="20" className={icon} />
                <StyledTextRegular>
                  <Trans>{t(title)}</Trans>
                </StyledTextRegular>
              </StyledSidebarItem>
            </StyledSidebarLink>
          )}
        />
      );
    }

    // if (subLink) {
    //   const lengthWithPerm = subLink.filter((item) => {
    //     if (!item?.permission) {
    //       return item;
    //     }
    //
    //     if (item?.permission.some((route) => permissions.includes(route))) {
    //       return item;
    //     }
    //   }).length;
    //
    //   return (
    //     <StyledSidebarLink
    //       as={as}
    //       to={to}
    //       className={match && "active_sub-link"}
    //       onClick={() => setAnimateOpen(true)}
    //     >
    //       <StyledSidebarItem collapsed={collapsed}>
    //         <StyledSpan fontSize="20" className={`icon-${icon}`} />
    //         <StyledTextRegular>
    //           <Trans>{t(title)}</Trans>
    //         </StyledTextRegular>
    //       </StyledSidebarItem>
    //
    //       <StyledSidebarSubLinkList
    //         animateOpen={animateOpen}
    //         isOpen={match && (!collapsed || toggled)}
    //         bodyHeight={lengthWithPerm * heightSubItem}
    //       >
    //         {subLink.map((subLink) => {
    //           const SidebarItemSubLinkWithPerm = withPermissionCheckComponent(
    //             SidebarItemSubLink,
    //             subLink?.permission
    //           );
    //
    //           return (
    //             <SidebarItemSubLinkWithPerm
    //               language={language}
    //               as={as}
    //               key={subLink.path}
    //               path={subLink.path}
    //               title={t(`${subRelation}SubLink.${subLink.title}`)}
    //             />
    //           );
    //         })}
    //       </StyledSidebarSubLinkList>
    //     </StyledSidebarLink>
    //   );
    // }

    return (
      <StyledSidebarLink
        as={as}
        to={to}
        className={match && "active"}
        onClick={() => dispatch(handleSidebarToggled())}
      >
        <StyledSidebarItem collapsed={sidebarCollapsed}>
          <StyledSpan fontSize="20" className={icon} />
          <StyledTextRegular>
            <Trans>{t(title)}</Trans>
          </StyledTextRegular>
        </StyledSidebarItem>
      </StyledSidebarLink>
    );
  };

  return sidebarItemFactory(subLink);
};

export default withTranslation("translation", { keyPrefix: "sidebar" })(SidebarItem);
