import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchClients = createAsyncThunk("clientsSlice/fetchClients", async (filterUrl, thunkAPI) => {
  try {
    const response = await axiosInstance(`/api/users${filterUrl}`, userAuthenticationConfig());

    return {
      data: response.data["hydra:member"],
      paginationInfo: {
        totalPageCount: response.data["hydra:totalPageCount"],
        totalItems: response.data["hydra:totalItems"],
      },
    };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.data);
  }
});

const fetchClientDetails = createAsyncThunk(
  "clientsSlice/fetchClientDetails",
  async (clientId, thunkAPI) => {
    try {
      const response = await axiosInstance(`/api/users/${clientId}`, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const banClient = createAsyncThunk(
  "clientsSlice/banClient",
  async ({ clientId, isBanned, message, twofa, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/users/${clientId}/ban`,
        {
          isBanned,
          message,
          twofa,
        },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeUserWidgetStatus = createAsyncThunk(
  "clientsSlice/changeUserWidgetStatus",
  async ({ id, widgetStatus }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/users/${id}/widget-state`,
        { widgetStatus },
        userAuthenticationConfig()
      );
      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const getUserReferralInfo = createAsyncThunk(
  "clientsSlice/getUserReferralInfo",
  async ({ id }, thunkAPI) => {
    try {
      const response = await axiosInstance.get(
        `/api/exchanger-referral-info/${id}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeUserReferralInfo = createAsyncThunk(
  "clientsSlice/changeUserReferralInfo",
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger-referral-user-infos/${id}`,
        { ...data },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const resetExchangerUserVerification = createAsyncThunk(
  "documents/resetExchangerUserVerification",
  async ({ userId, schema, twofa, callback }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-user-verifications/reset",
        {
          userId,
          schema,
          twofa,
        },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        callback,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchReferralUsersById = createAsyncThunk(
  "referralSystem/fetchReferralUsers",
  async ({ id, filterUrl }, thunkAPI) => {
    const url = `/api/users/referral-users/${id}${filterUrl}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());

      return {
        data: response.data.referrals,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchWithdrawalRequisitions = createAsyncThunk(
  "referralSystem/fetchWithdrawalRequisitions",
  async ({ id, filterUrl }, thunkAPI) => {
    const query = filterUrl
      ? filterUrl + (id ? `&exchangerUser.id=${id}` : "")
      : id
        ? `&exchangerUser.id=${id}`
        : "";
    const url = `/api/exchanger-withdrawal-requisitions${query}`;

    try {
      const response = await axiosInstance.get(url, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeReferralLifeTimeValue = createAsyncThunk(
  "clientsSlice/changeReferralLifeTimeValue",
  async ({ userId, referralLifeTimeValue = "1" }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/exchanger-users/update-info/${userId}`,
        {
          referralLifeTimeValue,
        },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const assignReferrer = createAsyncThunk(
  "clientsSlice/assignReferrer",
  async ({ referrerEmail, referralClientId }, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-users/assign-referrer",
        {
          referrerEmail,
          referralClientId,
        },
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const changeUserMonthlyLimit = createAsyncThunk(
  "clientsSlice/changeUserMonthlyLimit",
  async ({ id, ...data }, thunkAPI) => {
    try {
      const response = await axiosInstance.put(
        `/api/users/update-info/${id}`,
        data,
        userAuthenticationConfig()
      );
      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  assignReferrer,
  banClient,
  changeReferralLifeTimeValue,
  changeUserMonthlyLimit,
  changeUserReferralInfo,
  changeUserWidgetStatus,
  fetchClientDetails,
  fetchClients,
  fetchReferralUsersById,
  fetchWithdrawalRequisitions,
  getUserReferralInfo,
  resetExchangerUserVerification,
};
