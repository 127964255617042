const startReverseCountdown = (
  dateCreationTimestamp,
  remainingTimeInSeconds,
  onUpdate,
  onComplete
) => {
  if (!remainingTimeInSeconds) {
    return null;
  }

  const endTime = dateCreationTimestamp + remainingTimeInSeconds * 1000;

  const updateCountdown = () => {
    const currentTime = Date.now();
    const timeLeft = Math.max(0, endTime - currentTime);

    if (timeLeft === 0) {
      clearInterval(intervalId);
      if (onComplete) onComplete();
      return;
    }

    if (onUpdate) {
      onUpdate(timeLeft);
    }
  };

  const intervalId = setInterval(updateCountdown, 1000);
  updateCountdown();

  return () => clearInterval(intervalId);
};

const formatTimeLeft = (timeLeft) => {
  if (!timeLeft || isNaN(timeLeft)) {
    return { hours: "00", minutes: "00", seconds: "00" };
  }

  const seconds = Math.floor((timeLeft / 1000) % 60)
    .toString()
    .padStart(2, "0");
  const minutes = Math.floor((timeLeft / (1000 * 60)) % 60)
    .toString()
    .padStart(2, "0");
  const hours = Math.floor(timeLeft / (1000 * 60 * 60))
    .toString()
    .padStart(2, "0");

  return { hours, minutes, seconds };
};

export { formatTimeLeft, startReverseCountdown };
