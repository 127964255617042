import { createSlice } from "@reduxjs/toolkit";

import {
  assignReferrerReducer,
  banClientsReducer,
  changeReferralLifeTimeValueReducer,
  changeUserMonthlyLimitReducer,
  changeUserReferralInfoReducer,
  changeWidgetStatusExchangerReducer,
  fetchClientsDetailsReducer,
  fetchClientsReducer,
  fetchReferralUsersByIdReducer,
  fetchWithdrawalRequisitionsReducer,
  getUserReferralInfoReducer,
  resetExchangerUserVerificationReducer,
} from "./clientsReducers";

const initialState = {
  clients: [],
  error: null,
  loading: true,
  clientDetails: {},
  referralInfo: {
    data: {},
    loading: true,
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
  referralUsers: {
    list: [],
    loading: false,
  },
  withdrawalRequisitions: {
    data: {},
    loading: true,
  },
  banLoading: false,
  verificationLoading: false,
  assignReferrerLoading: false,
  changeMonthlyLimitLoading: false,
  referralsUserLife: {
    value: "1",
    loading: false,
  },
};

const clientsSlice = createSlice({
  name: "clientsSlice",
  initialState,
  reducers: {
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
    clearStateClients: (state) => {
      state.clients = [];
      state.loading = true;
      state.error = null;
    },
    clearStateReferralUsers: (state) => {
      state.referralUsers.list = [];
      state.referralUsers.loading = false;
    },
    clearStateWithdrawalRequisitions: (state) => {
      state.withdrawalRequisitions.list = [];
      state.withdrawalRequisitions.loading = false;
    },
    clearStateClientDetails: (state) => {
      state.loading = true;
      state.clientDetails = {};
      state.referralsUserLife = {};
    },
  },
  extraReducers: (builder) => {
    fetchClientsReducer(builder);
    fetchClientsDetailsReducer(builder);
    banClientsReducer(builder);
    changeWidgetStatusExchangerReducer(builder);
    getUserReferralInfoReducer(builder);
    changeUserReferralInfoReducer(builder);
    resetExchangerUserVerificationReducer(builder);
    fetchReferralUsersByIdReducer(builder);
    fetchWithdrawalRequisitionsReducer(builder);
    changeReferralLifeTimeValueReducer(builder);
    assignReferrerReducer(builder);
    changeUserMonthlyLimitReducer(builder);
  },
});

export const {
  setPaginationInfo,
  clearStateClients,
  clearStateClientDetails,
  clearStateReferralUsers,
  clearStateWithdrawalRequisitions,
} = clientsSlice.actions;

export default clientsSlice.reducer;
