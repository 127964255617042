export const permissionMainAdminEndpoints = {
  FILL_PAIR_UNITS: {
    name: "fill_pair_units",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_ENDPOINTS: {
    name: "fill_endpoints",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_GROUP_AND_ENDPOINT_PERMISSIONS: {
    name: "fill_group_and_endpoint_permissions",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_EXCHANGER_ENDPOINT_PERMISSION_FOR_NEW_USER: {
    name: "fill_exchanger_endpoint_permissions_for_new_user",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_EXCHANGER_PERMISSIONS: {
    name: "fill_exchanger_permissions",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_PAIRS: {
    name: "fill_pairs",
    hasFullAccess: true,
  },
  CHECK_DIFFERENCE_BETWEEN_ENDPOINTS_AND_GROUP_ENDPOINTS: {
    name: "check_difference_between_endpoints_and_group_endpoints",
    hasFullAccess: true,
  },
  PUT_CHANGE_AVATAR: {
    name: "put_changer_avatar",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  PUT_REMOVE_AVATAR: {
    name: "put_remove_avatar",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  TEST_EMAIL: {
    name: "test_email",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_BALANCES_GET_COLLECTION: {
    name: "api_balances_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_BALANCES_GET_ITEM: {
    name: "api_balances_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_CONNECTIONS_GET_COLLECTION: {
    name: "api_connections_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_CONNECTIONS_GET_ITEM: {
    name: "api_connections_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_ENDPOINTS_GET_COLLECTION: {
    name: "api_endpoints_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_ENDPOINTS_GET_ITEM: {
    name: "api_endpoints_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  POST_ENDPOINT_PERMISSION_BY_GROUP: {
    name: "post_endpoint_permission_by_group",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_ENDPOINT_PERMISSION_GET_COLLECTION: {
    name: "api_endpoint_permissions_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_ENDPOINT_PERMISSION_POST_COLLECTION: {
    name: "api_endpoint_permissions_post_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_ENDPOINT_PERMISSION_GET_ITEM: {
    name: "api_endpoint_permissions_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_ENDPOINT_PERMISSION_DELETE_ITEM: {
    name: "api_endpoint_permissions_delete_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_ENDPOINT_PERMISSION_BY_USER_ID: {
    name: "get_endpoint_permission_by_user_id",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_ENDPOINTS_LIST: {
    name: "get_endpoints_list",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_GROUP_PERMISSIONS_GET_COLLECTION: {
    name: "api_group_permissions_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_GROUP_PERMISSIONS_GET_ITEM: {
    name: "api_group_permissions_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_GET_COLLECTION: {
    name: "api_exchangers_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_GET_ITEM: {
    name: "api_exchangers_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_CHANGE_STATUS_ITEM: {
    name: "api_exchangers_change_status_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_CHANGE_CONNECTION_FIELDS_ITEM: {
    name: "api_exchangers_change_connection_fields_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_CONNECTION_GET_COLLECTION: {
    name: "api_exchanger_connections_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_CONNECTION_POST_COLLECTION: {
    name: "api_exchanger_connections_post_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_CONNECTION_GET_ITEM: {
    name: "api_exchanger_connections_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_DATABASE_CONNECTIONS_GET_COLLECTION: {
    name: "api_exchanger_database_connections_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_DATABASE_CONNECTIONS_GET_ITEM: {
    name: "api_exchanger_database_connections_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_DATABASE_CONNECTIONS_PUT_ITEM: {
    name: "api_exchanger_database_connections_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  HANDLE_EXCHANGER_SETTINGS: {
    name: "handle_exchanger_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_SETTINGS_GET_COLLECTION: {
    name: "api_exchanger_settings_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_SETTINGS_GET_ITEM: {
    name: "api_exchanger_settings_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_PAIR_UNITS_GET_COLLECTION: {
    name: "api_pair_units_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_PAIR_UNITS_GET_ITEM: {
    name: "api_pair_units_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_PAIR_UNITS_PUT_ITEM: {
    name: "api_pair_units_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_PAYMENT_SYSTEMS_GET_COLLECTION: {
    name: "api_payment_systems_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_PAYMENT_SYSTEMS_GET_ITEM: {
    name: "api_payment_systems_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVERS_GET_COLLECTION: {
    name: "api_servers_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVERS_POST_COLLECTION: {
    name: "api_servers_post_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVERS_GET_ITEM: {
    name: "api_servers_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVERS_PUT_ITEM: {
    name: "api_servers_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVICES_GET_COLLECTION: {
    name: "api_services_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_SERVICES_GET_ITEM: {
    name: "api_services_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_USERS_GET_COLLECTION: {
    name: "api_users_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_USERS_GET_ITEM: {
    name: "api_users_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_USERS_PATCH_ITEM: {
    name: "api_users_patch_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  UPDATE_DATABASE_SCHEMA: {
    name: "update_database_schema",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  UPDATE_DATABASE_SCHEMA_FOR_ALL_EXCHANGERS: {
    name: "update_database_schema_for_all_exchangers",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_BASE_CURRENCY_RATE_SERVICE: {
    name: "fill_base_currency_rate_service",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_RATES_GET_COLLECTION: {
    name: "api_exchanger_rates_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_RATES_GET_ITEM: {
    name: "api_exchanger_rates_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_RATES_CHANGE_OUR_MARKUP_ITEM: {
    name: "api_exchanger_rates_change_our_markup_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_USERS_CHANGE_PASSWORD: {
    name: "put_users_change_password",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_DEFAULT_HIDDEN_ATTRIBUTES_VALUE: {
    name: "fill_default_hidden_attributes_value",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_EXCHANGER_CONNECTIONS: {
    name: "fill_exchanger_connections",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_BALANCE_REFILLING_DATA: {
    name: "fill_balance_refilling_data",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  PUT_ENDPOINT_PERMISSION_EDIT_ACCESS: {
    name: "put_endpoint_permission_edit_access",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  FILL_ENDPOINT_PERMISSIONS_FOR_NEW_USER: {
    name: "fill_endpoint_permissions_for_new_user",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_BASE_CURRENCY_RATE_SERVICES_GET_COLLECTION: {
    name: "api_base_currency_rate_services_get_collection",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_BASE_CURRENCY_RATE_SERVICES_GET_ITEM: {
    name: "api_base_currency_rate_services_get_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_GET_PROJECT_ID_ITEM: {
    name: "api_exchangers_get_project_id_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_CHANGE_WIDGET_STATE_ITEM: {
    name: "api_exchangers_change_widget_state_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGER_SETTINGS_PATCH_ITEM: {
    name: "api_exchanger_settings_patch_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_AUTHENTICATOR_SECRET_IS_ACTIVE: {
    name: "get_authenticator_secret_is_active",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  PUT_AUTHENTICATOR_SECRET_REMOVE: {
    name: "put_authenticator_secret_remove",
    hasFullAccess: true,
    isTwoFaRequired: true,
  },
  API_EXCHANGER_CONNECTIONS_CHANGE_ENABLE_STATUS_ITEM: {
    name: "api_exchanger_connections_change_enable_status_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_EXCHANGERS_GET_CONNECTION_FIELDS_ITEM: {
    name: "api_exchangers_get_connection_fields_item",
    hasFullAccess: true,
    isTwoFaRequired: true,
  },
  REFRESH_TOKEN: {
    name: "refresh_token",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_INVOICE_DETAIL: {
    name: "get_invoice_detail",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  API_USERS_BAN_USER_ITEM: {
    name: "api_users_ban_user_item",
    hasFullAccess: true,
    isTwoFaRequired: true,
  },
  API_SERVICES_PUT_ITEM: {
    name: "api_services_put_item",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  POST_CALCULATOR_SETTINGS: {
    name: "post_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  DELETE_CALCULATOR_SETTINGS: {
    name: "delete_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  GET_CALCULATOR_SETTINGS: {
    name: "get_calculator_settings",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
  UPDATE_PAIR_UNITS: {
    name: "update_pair_units",
    hasFullAccess: true,
    isTwoFaRequired: false,
  },
};
