import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchSavedRequisites = createAsyncThunk(
  "savedRequisites/fetchSavedRequisites",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axiosInstance(
        `/api/exchanger-user-requisites${filterUrl}`,
        userAuthenticationConfig()
      );

      return {
        data: response.data.data,
        paginationInfo: {
          totalPageCount: response.data.totalPageCount,
          totalItems: response.data.totalItems,
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const updateSavedRequisites = createAsyncThunk(
  "savedRequisites/updateSavedRequisites",
  async ({ id, data }, thunkAPI) => {
    try {
      const response = await axiosInstance.patch(
        `/api/exchanger-user-requisites/${id}/update`,
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        id,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const deleteSavedRequisites = createAsyncThunk(
  "savedRequisites/deleteSavedRequisites",
  async (id, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(
        `/api/exchanger-user-requisites/${id}/delete`,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
        id,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

const fetchPairUnits = createAsyncThunk(
  "savedRequisites/fetchPairUnits",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axiosInstance(
        "/api/exchanger-pair-units/for-requisites",
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const createSavedRequisites = createAsyncThunk(
  "savedRequisites/createSavedRequisites",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post(
        "/api/exchanger-user-requisites/create",
        data,
        userAuthenticationConfig()
      );

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

export {
  createSavedRequisites,
  deleteSavedRequisites,
  fetchPairUnits,
  fetchSavedRequisites,
  updateSavedRequisites,
};
