import { useField } from "formik";

import InputGroup from "../inputGroup/InputGroup";

const TextInput = ({ name, isDisabled, label, icon, ...props }) => {
  const [field, { error }] = useField(name);
  const showError = !!error && !isDisabled;

  return (
    <InputGroup
      label={label}
      icon={icon}
      {...props}
      {...field}
      handleChange={field.onChange}
      error={showError && error}
    />
  );
};

export default TextInput;
