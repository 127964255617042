import React from "react";
import { Navigate } from "react-router-dom";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { handleLogout } from "../../store/auth/authSlice";
import { removeUserInfo } from "../../store/user/userSlice";
import { typeAccountRoutes } from "../consts";
import { getLocalizedLink } from "../customFunc/getLocalizedLink";
import getUserInfo from "../getUserInfo";

const PUBLIC_PERMISSION = { name: "public", hasFullAccess: true };

function withPermissionCheckPage(
  WrappedComponent,
  permissions,
  cookiesLang,
  checkFullAccess,
  dispatch
) {
  // eslint-disable-next-line react/display-name
  return function (props) {
    const propsWithPermissions = {
      ...props,
      permissions,
    };

    if (!permissions || permissions.some((perm) => perm.name === PUBLIC_PERMISSION.name)) {
      return <WrappedComponent {...propsWithPermissions} />;
    }

    const userPermissions = getUserInfo()?.endpoints;

    if (!userPermissions) {
      localStorage.removeItem("token");

      dispatch(handleLogout());
      dispatch(removeUserInfo());

      window.dispatchEvent(new CustomEvent("userLoggedOut"));

      return closableNotification(
        "Oops! Something went wrong. Please log in again to continue.",
        "error"
      );
    }

    const hasPermission = permissions.some((perm) =>
      userPermissions.some((userPermission) => userPermission.name === perm.name)
    );

    const isHaveFullAccess = checkFullAccess
      ? permissions.some((perm) =>
          userPermissions.some(
            (userPermission) =>
              userPermission.name === perm.name &&
              userPermission.hasFullAccess === perm.hasFullAccess
          )
        )
      : true;

    if (!hasPermission || !isHaveFullAccess) {
      return (
        <Navigate to={getLocalizedLink(`${typeAccountRoutes.CRM}/page-not-found`)} replace={true} />
      );
    }

    return <WrappedComponent {...propsWithPermissions} />;
  };
}

export default withPermissionCheckPage;
