import styled from "styled-components";

export const StyledLayoutWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 65px;
  flex: 1 1 auto;
  display: flex;
  position: relative;
  //background-color: ${({ theme }) => theme.sidebar.backgroundColor};

  @media screen and (max-width: 992px) {
    padding-top: 58px;
  }
`;

export const StyledUserLayoutWrapper = styled.div`
  width: 100%;
  max-width: ${({ changeWidth }) => (changeWidth ? "calc(100% - 68px)" : "calc(100% - 257px)")};
  margin-left: auto;
  flex: 1 1 auto;
  display: flex;
  //background-color: ${({ theme }) => theme.sidebar.backgroundColor};
  position: relative;
  //overflow-x: hidden;
  transition: width, left, right, 300ms;

  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
`;
