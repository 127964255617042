import { createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchBlackListWallet = createAsyncThunk("blackListWalletSlice/fetchBlackListWallet", async (filterUrl, thunkAPI) => {
    try {
      const response = await axiosInstance(`/api/exchanger-black-list-wallets${filterUrl}`, userAuthenticationConfig());

      return {
        data: response.data["hydra:member"],
        paginationInfo: {
          totalPageCount: response.data["hydra:totalPageCount"],
          totalItems: response.data["hydra:totalItems"],
        },
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

const addToBlackListWallet = createAsyncThunk("blackListWalletSlice/addBlackListWallet",
  async (data, thunkAPI) => {
    try {
      const response = await axiosInstance.post("/api/exchanger-black-list-wallets", data, userAuthenticationConfig());

      return {
        data: response.data,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data?.data);
    }
  }
);

const deleteWalletFromBlacklist = createAsyncThunk("blackListWalletSlice/deleteWalletFromBlacklist",
  async (id, thunkAPI) => {
    try {
      const response = await axiosInstance.delete(`/api/exchanger-black-list-wallets/${id}`, userAuthenticationConfig());

      return {
        data: response.data,
        id,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.data);
    }
  }
);

export {
  fetchBlackListWallet,
  addToBlackListWallet,
  deleteWalletFromBlacklist
};