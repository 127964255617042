import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from "react-router-dom";

import { updateTrafficLink } from "../../store/reports/asyncReports";

const refTokenName = "refToken";
const widgetTokenName = "widgetToken";
const defaultCookiePath = "/";

function useRefToken(dispatch) {
  const location = useLocation();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [_, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const refToken = params.get(refTokenName);
    const widgetToken = params.get(widgetTokenName);
    const trafficToken = params.get("traffic");

    if (refToken || widgetToken || trafficToken) {
      if (trafficToken) {
        setCookie("trafficToken", trafficToken, { path: defaultCookiePath });
        params.delete("traffic");
        dispatch(updateTrafficLink({ token: trafficToken }));
      }
      if (refToken) {
        setCookie(refTokenName, refToken, { path: defaultCookiePath });
        removeCookie(widgetTokenName);
        params.delete(refTokenName);
      }
      if (widgetToken) {
        setCookie(widgetTokenName, widgetToken, { path: defaultCookiePath });
        removeCookie(refTokenName);
        params.delete(widgetTokenName);
      }

      navigate({ search: params.toString() }, { replace: true });
    }
  }, [location.search, navigate]);
}

export default useRefToken;
