import { createSlice } from "@reduxjs/toolkit";

import getUserInfo from "../../utils/getUserInfo";
import {
  changeUserPasswordReducer,
  getUserSecretReducer,
  handleForgotPasswordReducer,
  handleGetPersonalDataReducer,
  handleTwoFaIsActiveReducer,
  recoverUserPasswordReducer,
  removeUserSecretReducer,
  setUserSecretReducer,
} from "./userReeducer";

const initialState = {
  loading: false,
  user: localStorage.getItem("token") ? getUserInfo() : null,
  personalData: {},
  twoFaIsActive: false,
  authenticatorSecret: false,
  errors: null,
};

const userSlice = createSlice({
  name: "userSlice",
  initialState,
  reducers: {
    setUserInfo: (state) => {
      state.user = getUserInfo();
    },
    removeUserInfo: (state) => {
      state.user = null;
    },
    setUserErrors: (state, action) => {
      state.errors = action;
    },
  },
  extraReducers: (builder) => {
    handleForgotPasswordReducer(builder);
    handleTwoFaIsActiveReducer(builder);
    getUserSecretReducer(builder);
    setUserSecretReducer(builder);
    removeUserSecretReducer(builder);
    recoverUserPasswordReducer(builder);
    changeUserPasswordReducer(builder);
    handleGetPersonalDataReducer(builder);
  },
});

export const { setUserInfo, removeUserInfo, setUserErrors } = userSlice.actions;
export default userSlice.reducer;
