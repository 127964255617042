import styled from "styled-components";

export const StyledPasswordValidatorWrapper = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const StyledPasswordValidatorItem = styled.div`
  padding: 1px 4px;
  color: ${({
    theme,
    isValid,
    dark
  }) => isValid !== null ? (dark ? theme.primary.first : theme.neutral.first) : theme.neutral.second};
  font-size: 12px;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid ${({
    theme,
    isValid
  }) => isValid !== null ? (isValid ? theme.success.first : theme.danger.first) : theme.neutral.twelfth};
  background-color: ${({
    theme,
    isValid
  }) => isValid !== null ? (isValid ? theme.success.second : theme.danger.second) : "transparent"};
  cursor: pointer;

  &:hover {
    background-color: ${({
      theme,
      isValid
    }) => isValid !== null ? (isValid ? theme.success.second : theme.danger.second) : theme.primary.fifth};
  }
`;
