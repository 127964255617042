import { createSlice } from "@reduxjs/toolkit";

import {
  createTrafficLinkReducer,
  deleteTrafficLinkByIdReducer,
  fetchTrafficLinkByIdReducer,
  fetchTrafficLinksReducer,
  fetchTrafficUsersReducer,
  getReportsInfoReducer,
} from "./reportsReducer";

const initialState = {
  reportsInfo: {},
  loading: true,
  errors: null,
  traffic: {
    data: [],
    loading: false,
    createLoading: false,
  },
  trafficById: {
    data: {},
    loading: true,
  },
  trafficUsers: {
    data: [],
    loading: true,
  },
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    clearStateReports: (state) => {
      state.reportsInfo = {};
      state.loading = true;
      state.errors = null;
    },
    clearStateTraffic: (state) => {
      state.traffic = {
        data: [],
        loading: false,
        createLoading: false,
      };
    },
    clearStateTrafficUsers: (state) => {
      state.trafficUsers = {
        data: [],
        loading: true,
      };
    },
    setReportsPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    getReportsInfoReducer(builder);
    fetchTrafficLinksReducer(builder);
    createTrafficLinkReducer(builder);
    fetchTrafficLinkByIdReducer(builder);
    deleteTrafficLinkByIdReducer(builder);
    fetchTrafficUsersReducer(builder);
  },
});

export const {
  clearStateReports,
  clearStateTraffic,
  clearStateTrafficUsers,
  setReportsPaginationInfo,
} = reportsSlice.actions;

export default reportsSlice.reducer;
