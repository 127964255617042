import styled from "styled-components";

export const StyledCustomCopyToClipboard = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ mr }) => mr && `margin-right: ${mr}px`};
  ${({ ml }) => ml && `margin-left: ${ml}px`};
  ${({ maxWidth }) => maxWidth && `width: ${maxWidth}px`};
  ${({ display }) => display && `display: ${display}`};
  ${({ border }) =>
    !border &&
    `
    width: 100%;
    overflow-wrap: break-word;
  `}
  cursor: ${({ cursor }) => (cursor ? cursor : "unset")};

  ${({ extraText }) =>
    extraText &&
    `
    width: fit-content;
     
    span.ellipses {
      width: fit-content;
      margin-right: 6px;
  `};
`;
export const StyledCopyIconAndTextBlock = styled.p`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
  border-radius: 12px;
  transition: all 0.3s ease;

  ${({ border, outlined, theme }) =>
    border &&
    outlined &&
    `
    padding: 5px 10px;
    border: 1px solid ${theme.neutral.six};
  `}
  ${({ border, theme }) =>
    !border &&
    `
    .ellipses {
      width: 100%;
      white-space: normal;
      overflow-wrap: break-word;  
    }
    
    .icon-ex-copy {
      padding-left: 6px;
    }
    
    &:hover {
      color: ${theme.neutral.black};
    }
  `}
  
  & .ellipses > .icon-ex-copy,
  .extra-text {
    transition: all 0.3s ease;
  }

  &:hover {
    border-color: ${({ theme }) => theme.primary.first};

    .ellipses > .icon-ex-copy,
    .extra-text {
      transition: all 0.3s ease;
      color: ${({ theme }) => theme.primary.second};
    }
  }

  .icon-ex-copy {
    font-size: 16px;
    color: ${({ theme }) => theme.input.inputIconColor};
    transition: all 0.3s ease;
    ${({ iconMr }) => iconMr && `margin-right: ${iconMr}px`};
    ${({ iconMl }) => iconMl && `margin-left: ${iconMl}px`};

    &:hover {
      transition: all 0.3s ease;
      color: ${({ theme }) => theme.primary.second};
    }
  }
`;
