import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/setupAxiosInterceptors";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";

const fetchLogs = createAsyncThunk(
  "logsSlice/fetchLogs",
  async (filterUrl, thunkAPI) => {
    try {
      const response = await axiosInstance(`/api/logs${filterUrl}`, userAuthenticationConfig());

      return {
        data: response.data
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

export { fetchLogs };