import { useGoogleLogin } from "@react-oauth/google";
import React from "react";

import { StyledButton } from "../../styles/styledButton";

const LoginGoogleButton = ({ handleSubmit, t }) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleSubmit(tokenResponse.access_token),
  });

  return (
    <StyledButton color="main" border onClick={login} type="button">
      <div className="social-login social-login_with-google">{t("socialLogin.google")}</div>
    </StyledButton>
  );
};

export default LoginGoogleButton;
