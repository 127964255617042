import { useEffect, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { toggleMobile } from "../../../store/theme/themeSlice";
import { getProfitsInfo } from "../../../store/userBalance/asyncUserBalance";
import { defaultRoute, exchangerRoles } from "../../../utils/consts";
import { checkPermissionByName } from "../../../utils/customFunc/checkPermission";
import { useMedia } from "../../../utils/customHooks/useMedia";
import { groupPermissionEndpoints } from "../../../utils/groupEndpoints/groupPermissionEndpoints";
import CustomLink from "../../elements/customLink/CustomLink";
import LanguageSelect from "../../elements/language/LanguageSelect";
import Logo from "../../elements/logo/Logo";
import Navigation from "../../elements/navigation/Navigation";
import BalanceButton from "../../userBalance/BalanceButton";
import UserInfo from "../../userInfo/UserInfo";
import {
  StyledHeaderContainer,
  StyledHeaderWrapper,
  StyledMobileSideBarIcon,
} from "./styledHeader";

const Header = ({ authenticated, isAccountPathname, t }) => {
  const dispatch = useDispatch();

  const { email, roles } = useSelector((store) => store.user.user) || {};
  const { sidebarToggled } = useSelector((store) => store.theme);
  const { profits, profitLoading } = useSelector((state) => state.userBalance);

  const isMobile = useMedia("(max-width: 992px)");

  const isUser = roles?.some((role) => role === exchangerRoles.USER);

  const haveAccess = useMemo(
    () => checkPermissionByName([groupPermissionEndpoints.API_EXCHANGER_BALANCES]),
    [authenticated]
  );

  const userAccountLink = () => {
    if (authenticated) {
      return defaultRoute;
    }

    return "/login";
  };

  useEffect(() => {
    if (isUser && haveAccess && authenticated) {
      dispatch(getProfitsInfo());
    }
  }, [authenticated]);

  return (
    <StyledHeaderWrapper isAccountPathname={isAccountPathname}>
      <StyledHeaderContainer>
        <div className="header-left">
          <Logo />
        </div>
        {!isAccountPathname && (
          <Navigation authenticated={authenticated} userAccountLink={userAccountLink} />
        )}
        <div className="header-right">
          {isUser && haveAccess && !isMobile && (
            <BalanceButton profits={profits} profitLoading={profitLoading} />
          )}
          {authenticated && email && !isMobile && (
            <UserInfo email={email} isMobile={isMobile} isAccountPathname={isAccountPathname} />
          )}
          <LanguageSelect />
          {isAccountPathname ? (
            <StyledMobileSideBarIcon
              authenticated={authenticated}
              show={sidebarToggled}
              onClick={() => dispatch(toggleMobile())}
            >
              <div className="navbar-top" />
              <div className="navbar-middle" />
              <div className="navbar-bottom" />
            </StyledMobileSideBarIcon>
          ) : !authenticated ? (
            <CustomLink className="header-right__account" to={userAccountLink()}>
              <span className="icon-ex-input-usename" />
              <span className="header-right__account-title">
                {!authenticated ? t("signIn") : t("myAccount")}
              </span>
            </CustomLink>
          ) : (
            ""
          )}
        </div>
      </StyledHeaderContainer>
    </StyledHeaderWrapper>
  );
};

export default withTranslation("translation", { keyPrefix: "navigation" })(Header);
