import styled from "styled-components";

import { StyledHeadingOne } from "../../styles/styledTypography";
import CustomLink from "../customLink/CustomLink";
import { StyledButton } from "../../styles/styledButton";
import { StyledBreakWord } from "../../styles/StyledBreakWord";

export const StyledPageHeadWrapper = styled.div`
  ${({ pb }) => (pb ? `padding-bottom: ${pb}px;` : "padding-bottom: 32px;")};
  display: flex;
  align-items: flex-end;
  justify-content: ${({ justifyContent }) => justifyContent};

  @media screen and (max-width: 768px) {
    padding-bottom: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
`;

export const StyledPageTitleContainer = styled.div`
  width: ${({ actionsInside }) => (actionsInside !== "false" ? "fit-content" : "100%")};
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`;

export const StyledPageTitle = styled(StyledHeadingOne)`
  max-width: 900px;
  width: 100%;
  position: relative;

  @media screen and (max-width: 768px) {
    font-size: 22px;
    line-height: 26px;

    ${StyledBreakWord} {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  @media screen and (max-width: 480px) {
    ${({ previous }) => previous ? `
      max-width: 190px;
    ` : `
      ${StyledBreakWord} {
        white-space: wrap;
      }
    `}
  }
`;

export const StyledChildrenContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  .create-button,
  .apply-button {
    min-width: 125px;
  }

  .apply-button {
    border-color: transparent;

    &:hover {
      color: ${({ theme }) => theme.primary.first};
      background-color: ${({ theme }) => theme.primary.third};
    }

    &_active:hover {
      color: ${({ theme }) => theme.neutral.white};
      background-color: ${({ theme }) => theme.primary.first};
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    flex-wrap: unset;

    .create-button,
    .apply-button {
      min-width: 125px;
      width: 100%;
      color: ${({ theme }) => theme.primary.first};
      background-color: ${({ theme }) => theme.primary.third};
      border-color: transparent;

      &_active {
        color: ${({ theme }) => theme.neutral.white};
        background-color: ${({ theme }) => theme.primary.first};
      }
    }
  }
`;

export const StyledPagePrevious = styled(CustomLink)`
  padding-right: 21px;
  color: ${({ theme }) => theme.neutral.third};
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.primary.first};
  }

  &::after {
    content: "/";
    color: ${({ theme }) => theme.neutral.third};
    position: absolute;
    left: calc(100% - 14px);
    bottom: 0;
  }

  @media screen and (max-width: 768px) {
    line-height: 20px;
  }
`;