import styled from "styled-components";

export const StyledDatepicker = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  display: grid;
  grid-template-columns: 100%;

  .react-date-picker__wrapper {
    padding-right: 16px;
    background-color: ${({ theme }) => theme.datepicker.backgroundColor};
    border: 1px solid
      ${({ theme, error }) => (error ? theme.danger.first : theme.datepicker.borderColor)};
    border-radius: 12px;
    ${({ theme, error }) => error && `border-color: ${theme.danger.first}`};
    cursor: pointer;
    display: flex;
    align-items: center;

    input,
    span {
      height: 100%;
      display: inline-flex;
      color: ${({ theme, error }) => (error ? theme.danger.first : theme.datepicker.textColor)};
      font-size: 14px;
    }

    // button {
    //   padding: unset;
    //
    //   svg {
    //     width: 14px;
    //     height: 14px;
    //   }
    //
    //   [class^="icon-"],
    //   [class*="icon-"] {
    //     position: absolute;
    //     height: unset;
    //     top: 9px;
    //     font-size: 16px;
    //   }
    //
    //   .icon-cancel_icon {
    //     left: 16px;
    //   }
    //
    //   .icon-calendar {
    //     right: 16px;
    //     color: ${({ theme }) => theme.primary.first};
    //   }
    // }

    .react-date-picker__inputGroup {
      padding: 11px 2px 11px 16px !important;
    }

    .react-date-picker__button {
      padding: 0;
      width: 14px;
      height: 14px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      .react-date-picker__calendar-button {
        margin-left: 8px;
      }

      span {
        color: ${({ theme }) => theme.datepicker.iconColor};
        font-size: 14px;
      }

      &:hover {
        span {
          color: ${({ theme }) => theme.primary.first};
          font-size: 14px;
        }
      }
    }

    .react-date-picker__calendar-button {
      margin-left: 6px;
    }
  }

  .react-date-picker__calendar {
    width: 277px;
    z-index: 15;
  }

  .react-calendar {
    background: ${({ theme }) => theme.neutral.white};
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 0 1px 8px rgb(0 0 0 / 20%);

    .react-calendar__navigation__arrow,
    .react-calendar__navigation__label,
    .react-calendar__tile {
      // color: ${({ theme }) => theme.datepicker.textColor};

      &:hover {
        color: #fff;
        background-color: ${({ theme }) => theme.primary.first};
        border-radius: 3px;
      }
    }

    .react-calendar__month-view__days__day--weekend {
      color: ${({ theme }) => theme.danger.first};
    }

    .react-calendar__tile--now,
    .react-calendar__tile--active {
      border-radius: 3px;
    }

    .react-calendar__tile--now {
      color: #ffffff;
      background-color: ${({ theme }) => theme.primary.second};
      opacity: 0.7;
    }

    .react-calendar__tile--active {
      color: #ffffff;
      background-color: ${({ theme }) => theme.primary.first};
      opacity: 1;
    }

    .react-calendar__tile:disabled {
      color: ${({ theme }) => theme.datepicker.textColor};
      cursor: not-allowed;
      opacity: 0.75;
    }

    .react-calendar__navigation button {
      min-width: 40px;
      height: 40px;
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: ${({ theme }) => theme.primary.first};
      opacity: 1;
    }

    .react-calendar__navigation button[disabled] {
      background-color: inherit;
      opacity: 0.5;
    }
  }
`;

export const StyledDatepickerLabel = styled.label`
  padding-bottom: 12px;
  color: ${({ theme }) => theme.neutral.second};
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  display: flex;
`;
