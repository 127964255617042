import { useEffect } from "react";
import { Trans } from "react-i18next";
import AuthCode from "react-auth-code-input";

import { maxLengthSymbols } from "../../../utils/consts";
import { StyledButton } from "../../styles/styledButton";
import { StyledParagraph } from "../../styles/styledTypography";
import CustomCopyToClipboard from "../copyToClipboard/CustomCopyToClipboard";
import LoadButton from "../spinner/ButtonSpinner";
import { StyledFirstLoginDataWrapper, StyledTwoFaForm } from "./styledTwoFa";
import { StyledLabel } from "../inputGroup/styledInputGroup";

const TwoFa = ({
  handleSubmit,
  t,
  tempSecret,
  tempQRCode,
  handleChange,
  value,
  setValue,
  loading,
  skipAutoSubmit,
  isSubmitDisabled = false,
}) => {
  useEffect(() => {
    if (value.length === maxLengthSymbols.TWO_FA_CODE && !skipAutoSubmit) {
      handleSubmit();
    }
  }, [value]);

  // const handleButtonClick = () => {
  //   navigator.clipboard.readText().then((text) => {
  //     const digitMatches = text.match(/\d/g);
  //     if (digitMatches) {
  //       const firstSixDigits = digitMatches.slice(0, 6).join("");
  //       setValue(firstSixDigits);
  //     } else {
  //       setValue("");
  //     }
  //   });
  // };

  return (
    <>
      {tempSecret && tempQRCode && (
        <StyledFirstLoginDataWrapper>
          <StyledParagraph mb={15}>
            <Trans
              t={t}
              i18nKey="authCode.installGoogleAuthenticator"
              components={{
                appStoreLink: (
                  <a
                    href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                    className="default-link"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
                googlePlayLink: (
                  <a
                    href="https://apps.apple.com/ru/app/google-authenticator/id388497605"
                    className="default-link"
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            />
          </StyledParagraph>
          <StyledParagraph>
            {t("authCode.confirmLoginWith2Fa")}
          </StyledParagraph>
          <CustomCopyToClipboard
            value={tempSecret}
            border={false}
            display="inline-block"
            textClassName="copy-block"
          />
          {tempQRCode && <img src={tempQRCode} alt="secret" />}
        </StyledFirstLoginDataWrapper>
      )}
      <StyledTwoFaForm onSubmit={handleSubmit}>
        <StyledLabel htmlFor="twofa">{t("twoFa.description")}</StyledLabel>
        <AuthCode
          id="twofa"
          length={maxLengthSymbols.TWO_FA_CODE}
          containerClassName="twofa-container"
          inputClassName="twofa-container__input"
          allowedCharacters="numeric"
          onChange={handleChange}
        />
        {!loading ? (
          <StyledButton
            mt="16"
            width="100%"
            color="main"
            type="submit"
            disabled={value.length !== maxLengthSymbols.TWO_FA_CODE || isSubmitDisabled}
          >
            {t("twoFa.submit")}
          </StyledButton>
        ) : (
          <LoadButton mt="16" width="100%" weight="600" color="main" text={t("twoFa.submit")} />
        )}
      </StyledTwoFaForm>
    </>
  );
};

export default TwoFa;
