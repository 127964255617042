import { NavLink } from "react-router-dom";

import i18nHelper from "../../../i18nHelper";

const CustomLink = ({ children, to, onClick, className = "", title = null, target = null }) => {
  // TODO: Previous multilanguage realization
  // const language = "/" + i18nHelper.getCurrentLanguage();

  return (
    <NavLink
      to={`/x${to}`}
      title={title}
      target={target}
      className={({ isActive }) => `${className} ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      {children}
    </NavLink>
  );
};

export default CustomLink;
