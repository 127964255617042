import { createSlice } from "@reduxjs/toolkit";

import {
  changeWithdrawalRequisitionStatusReducer,
  getWithdrawalRequisitionsReducer,
} from "./withdrawalRequisitionsReducer";

const initialState = {
  withdrawalRequisitions: null,
  errors: null,
  loading: true,
  statusUpdateLoading: false,
  paginationInfo: {
    totalItems: null,
    totalPageCount: null,
    itemsPerPage: null,
    page: null,
  },
};

const withdrawalRequisitionsSlice = createSlice({
  name: "withdrawalRequisitions",
  initialState,
  reducers: {
    clearStateWithdrawalRequisitions: (state) => {
      state.withdrawalRequisitions = null;
      state.errors = null;
      state.loading = true;
    },
    setPaginationInfo: (state, action) => {
      state.paginationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    getWithdrawalRequisitionsReducer(builder);
    changeWithdrawalRequisitionStatusReducer(builder);
  },
});

export const { clearStateWithdrawalRequisitions, setPaginationInfo } =
  withdrawalRequisitionsSlice.actions;

export default withdrawalRequisitionsSlice.reducer;
