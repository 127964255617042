import { createSlice } from "@reduxjs/toolkit";

import { closableNotification } from "../../components/elements/notification/ClosableNotification";
import { darkTheme, lightTheme } from "../../components/styles/theme/theme";
import i18nHelper from "../../i18nHelper";
import {
  defaultLanguage,
  exchangerSettingsTypes,
  supportedLanguagesRegex,
  themeColor,
} from "../../utils/consts";
import { defaultSiteConfig } from "../../utils/defaultSiteConfig";
import { fetchThemeConfig } from "./asyncThemeSlice";

const colorScheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

const examinationDefaultTheme = colorScheme ? themeColor.DARK : themeColor.LIGHT;

const setThemeModeByDefault = () => {
  if (localStorage.getItem("theme")) {
    return localStorage.getItem("theme") === themeColor.LIGHT ? lightTheme : darkTheme;
  }

  return colorScheme ? darkTheme : lightTheme;
};

const updateThemeColors = (theme, payload) => {
  // const updatedPrimary = payload
  //   .filter((item) => item.type === exchangerSettingsTypes.COLOR && item.name.startsWith("primary"))
  //   .reduce((acc, item) => {
  //     const key = item.name.replace("primary", "").toLowerCase();
  //
  //     if (Object.prototype.hasOwnProperty.call(theme.primary, key)) {
  //       acc[key] = item.value;
  //     }
  //
  //     return acc;
  //   }, {});
  //
  // const headerBackgroundColorItem = payload.find((item) => item.name === "headerBackgroundColor");
  //
  // return {
  //   ...theme,
  //   primary: {
  //     ...theme.primary,
  //     ...updatedPrimary,
  //   },
  //   ...(headerBackgroundColorItem && { headerBackgroundColor: headerBackgroundColorItem.value }),
  // };

  const brandColorItem = payload.find((item) => item.name === "brandColor");

  return {
    ...theme,
    ...(brandColorItem && { brandColor: brandColorItem.value }),
  };
};

const updateSiteConfig = (config, payload) => {
  let updatedConfig = { ...config };

  if (Array.isArray(payload)) {
    payload.forEach((item) => {
      if (item.type === exchangerSettingsTypes.INPUT) {
        updatedConfig[item.name] = item.value;
      } else if (item.type === exchangerSettingsTypes.SELECT) {
        updatedConfig[item.name] = item.value;
      } else if (item.type === exchangerSettingsTypes.IMAGE && item.mediaObjectUrl) {
        updatedConfig[item.name] = item.mediaObjectUrl;
      }
    });

    // TODO: Previous multilanguage realization
    // const languagePrefix = window.location.pathname.match(supportedLanguagesRegex);
    //
    // if (!languagePrefix) {
    //   i18nHelper.setCurrentLanguage(
    //     payload.find((item) => item.name === "defaultLanguage")?.value ?? defaultLanguage
    //   );
    // }
  }

  return updatedConfig;
};

const initialState = {
  theme: localStorage.getItem("theme") || examinationDefaultTheme,
  themeMode: setThemeModeByDefault(),
  error: null,
  loading: true,
  siteConfig: defaultSiteConfig,
  sidebarCollapsed: eval(localStorage.getItem("collapsed")),
  sidebarToggled: false
};

const themeSlice = createSlice({
  name: "themeSlice",
  initialState,
  reducers: {
    handleChangeTheme: (state) => {
      const examinationCurrentTheme = state.theme === themeColor.LIGHT;

      state.theme = examinationCurrentTheme ? themeColor.DARK : themeColor.LIGHT;

      state.themeMode = examinationCurrentTheme ? darkTheme : lightTheme;

      localStorage.setItem("theme", state.theme);
    },
    handleSidebarCollapse: (state, action) => {
      if (action) {
        state.sidebarCollapsed = action.payload;

        return;
      }

      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    handleSidebarToggled: (state, action) => {
      if (action) {
        state.sidebarToggled = action.payload;

        return;
      }

      state.sidebarToggled = false;
    },
    handleCloseSideBar: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
      localStorage.setItem("collapsed", String(state.sidebarCollapsed));

      state.sidebarToggled = false;
    },

    toggleMobile: (state) => {
      state.sidebarToggled = !state.sidebarToggled;
      state.sidebarCollapsed = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchThemeConfig.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchThemeConfig.fulfilled, (state, action) => {
      state.siteConfig = updateSiteConfig(defaultSiteConfig, action.payload);
      state.lightTheme = updateThemeColors(lightTheme, action.payload);
      state.darkTheme = updateThemeColors(darkTheme, action.payload);
      state.themeMode = state.theme === themeColor.LIGHT ? state.lightTheme : state.darkTheme;
      state.loading = false;
    });
    builder.addCase(fetchThemeConfig.rejected, (state, action) => {
      closableNotification(action.payload, "error");
      state.loading = false;
    });
  },
});

export const { handleChangeTheme, handleSidebarCollapse, handleSidebarToggled, handleCloseSideBar, toggleMobile } = themeSlice.actions;

export default themeSlice.reducer;
