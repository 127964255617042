import styled from "styled-components";

export const StyledCheckboxWrapper = styled.div`
  ${({ mt }) => mt && `margin-top: ${mt}px`};
  ${({ mb }) => mb && `margin-bottom: ${mb}px`};
  ${({ margin }) => margin && `margin: ${margin}`};
  display: inline-flex;
  align-items: center;
`;

export const StyledCheckboxLabel = styled.label`
  ${({ position }) => position === "right" ? "padding-left: 8px" : position === "left" ? "padding-right: 8px" : null};
  ${({ padding }) => padding && `padding: ${padding}`};
  padding-bottom: 1px;
  color: ${({ theme }) => theme.neutral.second};
  font-size: 14px;
  line-height: 14px;
  text-align: left;
  cursor: pointer;
`;
