import { createSlice } from "@reduxjs/toolkit";

import { fetchTransactionDetailsReducer } from "./transactionDetailsReducers";

const initialState = {
  loading: true,
  transactionDetails: null,
};

const transactionDetailsSlice = createSlice({
  name: "transactionDetailsSlice",
  initialState,
  reducers: {
    clearTransactionDetailsStore: (state) => {
      state.loading = true;
      state.transactionDetails = null;
    },
    updateTransactionDetailsStore: (state, action) => {
      const pusherObject = JSON.parse(action.payload);

      state.transactionDetails = {
        ...state.transactionDetails,
        ...pusherObject,
        invoiceAttributes: Array.isArray(pusherObject.invoiceAttributes) ? pusherObject.invoiceAttributes : state.transactionDetails.invoiceAttributes || [],
        flowData: Array.isArray(pusherObject.flowData) ? pusherObject.flowData : state.transactionDetails.flowData || [],
      };
    }
  },
  extraReducers: (builder) => {
    fetchTransactionDetailsReducer(builder);
  },
});

export const { clearTransactionDetailsStore, updateTransactionDetailsStore } = transactionDetailsSlice.actions;

export default transactionDetailsSlice.reducer;
